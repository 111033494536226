import React, {useRef, useState} from "react";
import {useNavigate} from 'react-router-dom';
import './UserMenu.scss';
import {useDispatch, useSelector} from 'react-redux';
import {setLoginUserFalse} from '../redux/Login/Login.actions';
import useOutsideClick from './hooks/clickOutside.hook';


function UserMenu() {

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const ref = useRef();

    const signOutBtn = useRef();

    const getUserInfo = useSelector(state => state.login.userInfo);

    const [openMenu, setOpenMenu] = useState(false);

    useOutsideClick(ref, async () => {
        setOpenMenu(false);
    });

    const signOut = () => {
        dispatch(setLoginUserFalse());
        navigate('/login');
    }

    return (
        <section ref={ref} id="user-menu-section">
            <button type="button" id="user-menu-wrapper" onClick={() => setOpenMenu(!openMenu)}>
                <span>{getUserInfo?.firstName.charAt(0)}{getUserInfo.lastName?.charAt(0)}</span>
            </button>
            <section id="user-menu-dropdown-container" className={openMenu ? 'user-menu-open' : 'user-menu-closed'}>
                <div id="user-menu-dropdown-top">
                    <div id="user-menu-info-container">
                        <h3>{getUserInfo?.firstName} {getUserInfo?.lastName}</h3>
                        <h4>{getUserInfo?.email}</h4>
                    </div>

                </div>
                <div id="user-menu-dropdown-bottom">
                    <div id="user-menu-dropdown-bottom-wrapper">
                        <button ref={signOutBtn} onClick={() => signOut()}>
                            <span>Sign out</span>
                        </button>
                    </div>
                </div>
            </section>
        </section>
    )
}

export default UserMenu;
