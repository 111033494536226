import {SIDE_MENU_SELECTION} from './sideMenu.types';
import produce from 'immer';

const INITIAL_STATE = {
    sideMenuSelection: 1
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SIDE_MENU_SELECTION:
            return produce(state, draft => {
                draft.sideMenuSelection = Number(action.payload);
            });
        default:
            return state;
    }
}

export default reducer;
