import {HIDE_OVERLAY, SHOW_OVERLAY_ONLY, TOGGLE_OVERLAY} from './overlay.types';

export const toggleOverlay = (payload) => {
    return {
        type: TOGGLE_OVERLAY,
        payload: payload
    };
};

export const showOverlayOnly = () => {
    return {
        type: SHOW_OVERLAY_ONLY
    }
}

export const hideOverlay = () => {
    return  {
        type: HIDE_OVERLAY
    }
}
