import {createSelector} from 'reselect';

export const selectedBanner = state => state.banner.selectedBanner;
export const selectedTemplate = state => state.banner.selectedTemplate;
export const banners = state => state.banner.banners;
export const templates = state => state.banner.templates;

export const getSelectedBanner = createSelector(
    [banners, selectedBanner],
    (banners, selectedBanner) => banners.byId[selectedBanner]
);

export const getSelectedTemplate = createSelector(
    [templates, selectedTemplate],
    (templates, selectedTemplate) => templates.byId[selectedTemplate]
);

export const filterBannersBySelectedTemplate = createSelector(
    [banners, selectedTemplate],
    (banners, selectedTemplate) => Object.values(banners.byId).filter(value => value.template === selectedTemplate)
);

export const getNamingConvention = createSelector(
    [templates, selectedTemplate],
    (templates, selectedTemplate) => templates.byId[selectedTemplate].namingConvention
)

