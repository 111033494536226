import React, {useEffect} from 'react';

function useOutsideClick(ref, callback) {

    useEffect(() => {

        document.addEventListener('mousedown', onClickOutside);
        document.addEventListener("touchstart", onClickOutside);

        return () => {
            document.removeEventListener('mousedown', onClickOutside);
            document.addEventListener("touchstart", onClickOutside);
        }
    }, [ref]);

    const onClickOutside = (event) => {
            if (!ref.current || !ref.current.contains(event.target)) {
                callback();
            }
    };

}

export default useOutsideClick;
