import { combineReducers } from 'redux';
import bannerReducer from './Banner/banner.reducer';
import modalReducer from './Modal/modal.reducer';
import overlayReducer from './Overlay/overlay.reducer';
import sideMenuReducer from './SideMenu/sideMenu.reducer';
import sidePanelReducer from './SidePanel/sidePanel.reducer';
import topBarReducer from './TopBar/topBar.reducer';
import loginReducer from './Login/Login.reducer';
import recentDesignsReducer from './RecentDesigns/RecentDesigns.reducer';


const rootReducer = combineReducers({
    login: loginReducer,
    recentDesigns: recentDesignsReducer,
    banner: bannerReducer,
    modal: modalReducer,
    overlay: overlayReducer,
    sideMenu: sideMenuReducer,
    sidePanel: sidePanelReducer,
    topBar: topBarReducer
});

export default rootReducer;
