import {SET_LOGGED_IN_FALSE, SET_LOGIN_USER_INFO, SET_LOGGED_IN_TRUE, SET_ONLINE, SET_SAVING_STATUS} from './Login.types';
import produce from 'immer';

const INITIAL_STATE = {
    isOnline: navigator.onLine,
    saving: '',
    userLoggedIn: false,
    userInfo: {
        id: '',
        email: '',
        firstName: '',
        lastName: '',
        accessToken: '',
        refreshToken: ''
    }
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_LOGGED_IN_TRUE:
            return produce(state, draft => {
                draft.userLoggedIn = true;
            });
        case SET_LOGGED_IN_FALSE:
            return produce(state, draft => {
                draft.userLoggedIn = false;
                draft.userInfo = INITIAL_STATE.userInfo;
            });
        case SET_LOGIN_USER_INFO:
            return produce(state, draft => {
                draft.userInfo = {...action.payload}
            });
        case SET_SAVING_STATUS:
            return produce(state, draft => {
                draft.saving = action.payload
            });
        case SET_ONLINE:
            return produce(state, draft => {
                draft.isOnline = action.payload
            });
        default:
            return state;
    }
}

export default reducer;
