import {SET_IN_CREATE_DESIGN_MODE, SET_SAVE_STATUS_ICON_VISIBILITY, TOGGLE_DARK_MODE} from './topBar.types';

export const toggleDarkMode = () => {
    return {
        type: TOGGLE_DARK_MODE
    }
}

export const setInCreateDesignMode = (payload) => {
    return {
        type: SET_IN_CREATE_DESIGN_MODE,
        payload: payload
    }
}

export const setSaveStatusIconVisibility = (payload) => {
    return {
        type: SET_SAVE_STATUS_ICON_VISIBILITY,
        payload: payload
    }
}
