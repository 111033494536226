import {HIDE_MODAL, SET_NEW_SLOT, SHOW_MODAL} from './modal.types';


export const showModal = (payload) => {
    return {
        type: SHOW_MODAL,
        payload: payload
    }
}

export const hideModal = () => {
    return {
        type: HIDE_MODAL
    }
}

export const setNewSlot = (payload) => {
    return {
        type: SET_NEW_SLOT,
        payload: payload
    }
}
