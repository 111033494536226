import {SIDE_MENU_SELECTION} from './sideMenu.types';


export const sideMenuSelect = (payload) => {
    return {
        type: SIDE_MENU_SELECTION,
        payload: payload
    }
}

