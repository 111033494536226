import React, {Suspense, lazy, useEffect} from "react";
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import ProtectedRoute from './components/routing/ProtectedRoute';
import {useDispatch, useSelector} from 'react-redux';
import RecentDesigns from './components/routing/RecentDesigns';
import {setOnline} from './redux/Login/Login.actions';

const LogIn = lazy(() => import('./components/routing/Login'));
const NoPage = lazy(() => import('./components/error_pages/NoPage'));
const PhotoShop = lazy(() => import('./components/PhotoShop'));

export default function App() {

    const dispatch = useDispatch();

    const loggedInUser = useSelector(state => state.login.userLoggedIn);

    useEffect(() => {
        window.addEventListener('offline', () => dispatch(setOnline(false)));
        window.addEventListener('online', () => dispatch(setOnline(true)));

        return () => {
            window.removeEventListener('offline', () => dispatch(setOnline(false)));
            window.removeEventListener('online', () => dispatch(setOnline(true)));
        }
    }, []);

    return (
        <BrowserRouter>
            <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                    <Route element={<ProtectedRoute user={loggedInUser} />}>
                        <Route index element={<PhotoShop/>}/>
                        <Route path="Recent-Designs" element={<RecentDesigns/>}/>
                    </Route>
                    <Route path="login" element={<LogIn/>}/>
                    <Route path="*" element={<NoPage/>}/>
                </Routes>
            </Suspense>
        </BrowserRouter>
    )
}
