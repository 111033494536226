import {TOGGLE_PANEL} from './sidePanel.types';
import {ZOOM_CANVAS} from './sidePanel.types';

const INITIAL_STATE = {
    show: true,
    scale: 0.6
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TOGGLE_PANEL:
            return {
                ...state, show: !state.show
            }
        case ZOOM_CANVAS:
            return {
                ...state, scale: action.payload
            }
        default:
            return state;
    }
}

export default reducer;
