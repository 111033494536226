import {HIDE_OVERLAY, SHOW_OVERLAY_ONLY, TOGGLE_OVERLAY} from './overlay.types';
import produce from 'immer';

const INITIAL_STATE = {
    overlay: false,
    message: "Loading..."
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TOGGLE_OVERLAY:
            return produce(state, draft => {
                draft.overlay = !state.overlay;
                draft.message = action?.payload || INITIAL_STATE.message;
            });
        case SHOW_OVERLAY_ONLY:
            return produce(state, draft => {
                draft.overlay = true;
                draft.message = null;
            })
        case HIDE_OVERLAY:
            return produce(state, draft => {
                draft.overlay = false;
                draft.message = null;
            })
        default:
            return state;
    }
}

export default reducer;
