import {HIDE_MODAL, SET_NEW_SLOT, SHOW_MODAL} from './modal.types';
import produce from 'immer';

const INITIAL_STATE = {
    modal: false,
    mode: null,
    newSlot: false
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SHOW_MODAL:
            return produce(state, draft => {
                draft.modal = true;
                draft.mode = action.payload;
            });
        case HIDE_MODAL:
            return produce(state, draft => {
                draft.modal = false;
                draft.mode = null;
            });
        case SET_NEW_SLOT:
            return produce(state, draft => {
                draft.newSlot = action.payload;
            });
        default:
            return state;
    }
}

export default reducer;
