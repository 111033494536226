import axios from 'axios';
import {toggleOverlay} from './redux/Overlay/overlay.actions';
import {filterBannersBySelectedTemplate, getNamingConvention, getSelectedTemplate} from './redux/Banner/banner.selector';
import {loadBannersFromDB} from './redux/Banner/banner.actions';
import {toJpeg, toBlob} from 'html-to-image';
import {setIsProjectLoaded, setUsersSaveId} from './redux/RecentDesigns/RecentDesigns.actions';
import {loginUser, setLoginUserInfo, setSavingStatus} from './redux/Login/Login.actions';
import {setNewSlot} from './redux/Modal/modal.actions';
import {setSaveStatusIconVisibility} from './redux/TopBar/topBar.actions';


const showOverlay = (dispatch, message) => {
    return new Promise((resolve) => {
        // TODO: Use states, do not touch the DOM directly
        document.querySelector('body').classList.add('wait');
        document.querySelector('button#download-images-btn').disabled = true;
        resolve(dispatch(toggleOverlay(message)));
    });
}

const hideOverlay = async (dispatch) => {
    document.querySelector('button#download-images-btn').disabled = false;
    document.querySelector('body').classList.remove('wait');
    await dispatch(toggleOverlay());
}

const buildCanvas = async (getState, selectedTemplate, formData) => {
    const namingConvention = getState().banner.templates.byId[selectedTemplate].namingConvention.trim();
    // TODO: You should find a way to forward Ref of banner
    const banners = document.querySelectorAll('div.banner[data-enabled="true"]');

    for (const banner of banners) {
        await toBlob(banner, {cacheBust: true, style: {margin: 0}, pixelRatio: 2}).then((blob) => {
            formData.append('file', blob, `${banner.offsetWidth}x${banner.offsetHeight}_${namingConvention === '' ? `img_${banner.dataset.id}` : namingConvention}.jpg`);
        });
    }
};

export const ThunkCapture = () => async (dispatch, getState) => {
    const formData = new FormData();
    const selectedTemplate = getState().banner.selectedTemplate;
    const linkingUrl = getState().banner.templates.byId[selectedTemplate].linkingUrl.trim();

    await showOverlay(dispatch, "Downloading Zip");

    buildCanvas(getState, selectedTemplate, formData)
        .then(() => {
            formData.append('linkingUrl', linkingUrl !== '' ? linkingUrl : 'NO LINK PROVIDED');
        })
        .then(async () => {
            const response = await axios.post('/api/download-banners', formData, {responseType: 'blob'})
                .catch(async () => {
                    await hideOverlay(dispatch);
                });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'JPGs.zip'); //or any other extension
            document.body.appendChild(link);
            link.click();
            link.remove();
            await hideOverlay(dispatch);
        });
}

export const ThunkPreviewReview = () => async (dispatch, getState) => {
    const formData = new FormData();
    const selectedTemplate = getState().banner.selectedTemplate;
    const linkingUrl = getState().banner.templates.byId[selectedTemplate].linkingUrl.trim();
    const namingConvention = getNamingConvention(getState());

    await showOverlay(dispatch, "Uploading to Google Cloud");

    buildCanvas(getState, selectedTemplate, formData)
        .then(() => {
            formData.append('linkingUrl', linkingUrl !== '' ? linkingUrl : 'NO LINK PROVIDED');
            formData.append('namingConvention', namingConvention !== '' ? namingConvention : 'WAS NOT PROVIDED');
        })
        .then(async () => {
            return await axios.post('/api/upload-to-gcp', formData, {responseType: 'blob'})
                .catch(async () => {
                    await hideOverlay(dispatch);
                });
        })
        .then(async response => {
            return response.data?.text().then(text => {
                return window.open(JSON.parse(text).url, '_blank', 'noopener,noreferrer');
            })
                .catch(async () => {
                    await hideOverlay(dispatch);
                })
        })
        .then(async () => {
            await hideOverlay(dispatch);
        });
}

export const thunkSetSavingStatusToSaving = (status) => async (dispatch) => {
    dispatch(setSaveStatusIconVisibility(true));
    dispatch(setSavingStatus(status));
}

export const ThunkMakeACopyBanners = (saveId, templateJsonData) => async () => {
    await axios.post('/api/copy/banners', {
        saveId: saveId,
        templateJsonData: templateJsonData
    })
        .then((response) => {
            console.log(response);
        })
        .catch(err => {
            console.log(err);
        })
}

export const ThunkSaveBanners = async (dispatch, getState) => {

    let firstBanner = document.querySelectorAll('div.banner[data-enabled="true"]')[0];

    // TODO: Handle error
    const base64code = await toJpeg(firstBanner, {
        cacheBust: false,
        style: {
            margin: 0
        },
        pixelRatio: 0.5
    })
        .then((dataUrl) => {
            return dataUrl;
        });

    await axios.post('/api/save/banners', {
        userId: getState().login.userInfo?.id,
        thumbNail: base64code,
        bannerJsonData: filterBannersBySelectedTemplate(getState()),
        templateJsonData: getSelectedTemplate(getState())
    })
        .then((response) => {
            if (response.status === 201) {
                dispatch(thunkSetSavingStatusToSaving('saved'));
            } else {
                dispatch(thunkSetSavingStatusToSaving('dbError'));
            }
            dispatch(setUsersSaveId(response.data.saveId));
        })
        .catch(err => {
            // You might want to show response in an error pop up messaging system
            console.log(err);
            dispatch(thunkSetSavingStatusToSaving('dbError'));
        });
};

export const ThunkLoadBanners = (saveId) => async (dispatch) => {
    // TODO: return response and/or error
    await axios.get(`/api/load/banners/${saveId}`)
        .then((response) => {
            dispatch(loadBannersFromDB(response.data));
        })
        .catch(err => {
            console.log(err);
            console.log(err.data);
        });
};

export const ThunkUpdateBanners = async (saveId, dispatch, getState) => {
    let firstBanner = document.querySelectorAll('div.banner[data-enabled="true"]')[0];

    // TODO: Handle error
    const base64code = await toJpeg(firstBanner, {cacheBust: true, style: {margin: 0}, pixelRatio: 0.5})
        .then((dataUrl) => {
            return dataUrl;
        });

    await axios.patch(`/api/update/banners/${saveId}`, {
        userId: getState().login.userInfo?.id,
        thumbNail: base64code,
        bannerJsonData: filterBannersBySelectedTemplate(getState()),
        templateJsonData: getSelectedTemplate(getState())
    })
        .then(response => {
            if (response.status === 201) {
                dispatch(thunkSetSavingStatusToSaving('saved'));
            } else {
                dispatch(thunkSetSavingStatusToSaving('dbError'));
            }
        })
        .catch(err => {
            dispatch(thunkSetSavingStatusToSaving('dbError'));
            console.log(err);
            console.log(err.data);
        });
};

export const ThunkSaveOrUpdateBanners = () => async (dispatch, getState) => {

    setTimeout(() => {
        dispatch(setSaveStatusIconVisibility(false))
    }, 5000);

    const newSlot = getState().modal.newSlot;
    const inCreateDesignMode = getState().topBar.inCreateDesignMode;

    if (newSlot) {
        dispatch(setUsersSaveId(0));
    }

    dispatch(setNewSlot(false));

    const usersSaveId = getState().recentDesigns.usersSaveId;

    if (inCreateDesignMode) {
        dispatch(setIsProjectLoaded(true));
    }

    if (usersSaveId === 0) {
        return ThunkSaveBanners(dispatch, getState);
    } else {
        return ThunkUpdateBanners(usersSaveId, dispatch, getState);
    }


};

export const ThunkUpdateProjectName = (saveId, templateJsonData) => async () => {

    await axios.patch('/api/update/banner/naming-convention', {
        saveId: saveId,
        templateJsonData: templateJsonData
    })
        .catch(err => {
            console.log(err);
            console.log(err.data);
        })
};

export const ThunkDeleteBanners = (saveId) => async () => {
    return await axios.delete(`/api/delete/banners/${saveId}`)
        .then(response => {
            return response;
        })
        .catch(err => {
            return err;
        });
};

export const ThunkLoadRecentDesigns = () => async (dispatch, getState) => {
    const userId = getState().login.userInfo?.id;
    return await axios.get(`/api/load/recent-designs/${userId}`)
        .then((response) => {
            return response.data.sort((a, b) => new Date(b.updated_at || b.created_at) - new Date(a.updated_at || a.created_at));
        })
        .catch(err => {
            return err;
        });
};

export const ThunkLoginUser = (email, password) => async (dispatch) => {
    return await axios.post('/api/user/login', {
        'email': email,
        'password': password
    })
        .then((res) => {

            let userInfo = {
                id: res.data.id || '',
                email: res.data.email || '',
                firstName: res.data.firstName || '',
                lastName: res.data.lastName || '',
                accessToken: res.data.accessToken,
                refreshToken: res.data.refreshToken,
            }

            dispatch(setLoginUserInfo(userInfo));
            dispatch(loginUser(true));
            return res
        })
        .catch((err) => {
            return err
        });
};
