import produce from 'immer';
import {SET_IN_CREATE_DESIGN_MODE, SET_SAVE_STATUS_ICON_VISIBILITY, TOGGLE_DARK_MODE} from './topBar.types';

const INITIAL_STATE = {
    darkMode: false,
    inCreateDesignMode: false,
    showStatusIcon : false
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TOGGLE_DARK_MODE:
            return {
                ...state, darkMode: !state.darkMode
            }
        case SET_IN_CREATE_DESIGN_MODE:
            return produce(state, draft => {
                draft.inCreateDesignMode = action.payload;
            });
        case SET_SAVE_STATUS_ICON_VISIBILITY:
            return produce(state, draft => {
                draft.showStatusIcon = action.payload;
            });
        default:
            return state;
    }
}

export default reducer;
