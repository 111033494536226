import {SET_GRID_ROW_ITEMS, SET_IS_PROJECT_LOADED, SET_PROJECT_NAME_EDITABLE, SET_USERS_SAVE_ID} from './RecentDesigns.types';


export const setGridRowItems = (payload) => {
    return {
        type: SET_GRID_ROW_ITEMS,
        payload: payload
    }
}

export const setUsersSaveId = (payload) => {
    return {
        type: SET_USERS_SAVE_ID,
        payload: payload
    }
}

export const setProjectNameEditable = (payload) => {
    return {
        type: SET_PROJECT_NAME_EDITABLE,
        payload: payload
    }
}

export const setIsProjectLoaded = (payload) => {
    return {
        type: SET_IS_PROJECT_LOADED,
        payload: payload
    }
}
