import {SET_LOGGED_IN_FALSE, SET_LOGIN_USER_INFO, SET_LOGGED_IN_TRUE, SET_ONLINE, SET_SAVING_STATUS} from './Login.types';


export const loginUser = (payload) => {
    return {
        type: SET_LOGGED_IN_TRUE,
        payload: payload
    };
}

export const setSavingStatus = (payload) => {
    return {
        type: SET_SAVING_STATUS,
        payload: payload
    }
}

export const setLoginUserInfo = (payload) => {
    return {
        type: SET_LOGIN_USER_INFO,
        payload: {...payload}
    };
}

export const setLoginUserFalse = () => {
    return {
        type: SET_LOGGED_IN_FALSE
    }
}

export const setOnline = (payload) => {
    return {
        type: SET_ONLINE,
        payload: payload
    }
}
