import React, {useEffect, useRef, useState} from "react";
import './RecentDesigns.scss';
import threeDots from '../../assets/icons/three-dots.svg';
import copyIcon from '../../assets/icons/copy-icon.svg';
import trashIcon from '../../assets/icons/trash-icon.svg';
import {GrEdit} from 'react-icons/gr';
import TopBar from '../TopBar';
import {useDispatch, useSelector} from 'react-redux';
import {ThunkDeleteBanners, ThunkLoadBanners, ThunkLoadRecentDesigns, ThunkMakeACopyBanners, ThunkUpdateProjectName} from '../../thunks';
import {changePersistentNamingConvention, selectTemplate} from '../../redux/Banner/banner.actions';
import {sideMenuSelect} from '../../redux/SideMenu/sideMenu.actions';
import {useNavigate} from 'react-router-dom';
import {setGridRowItems, setIsProjectLoaded, setUsersSaveId} from '../../redux/RecentDesigns/RecentDesigns.actions';
import useOutsideClick from '../hooks/clickOutside.hook';
import ProjectNameInput from './projectNameInput';
import {setInCreateDesignMode} from '../../redux/TopBar/topBar.actions';


function GridRowMenu({openMenu, firstName, lastName, timeStamp, saveId, setOpenMenu, templateJson}) {

    const ref = useRef();
    const dispatch = useDispatch();

    useOutsideClick(ref, () => {
        setOpenMenu(false);
    });

    const makeACopyOfBanners = async () => {
        let templateData = {...templateJson};
        templateData.namingConvention = `${templateJson?.namingConvention ? templateJson?.namingConvention : 'Unnamed Project'} copy`
        await dispatch(ThunkMakeACopyBanners(saveId, templateData));
        // The 2 lines below basically refresh recent design list
        const response = await dispatch(ThunkLoadRecentDesigns());
        dispatch(setGridRowItems(response));
        setOpenMenu(false);
    }

    const deleteBanners = async (event, saveId) => {

        event.stopPropagation();

        try {
            await dispatch(ThunkDeleteBanners(saveId));
            const response = await dispatch(ThunkLoadRecentDesigns());
            dispatch(setGridRowItems(response));
        } catch (err) {
            // TODO: An Error messaging system needs to be implemented
            console.log(err)
        }
    }

    return (
        <div ref={ref} id="grid-row-menu" className={openMenu ? 'grid-menu-open' : 'grid-menu-closed'} onMouseDown={(event) => event.stopPropagation()}>
            <div id="grid-row-menu-top">
                <div id="grid-row-menu-top-wrapper">
                    <span>RECENTLY MODIFIED</span>
                    <span>{firstName} {lastName} : {timeStamp}</span>
                </div>
            </div>
            <div id="grid-row-menu-selections-container">
                <div className="grid-row-menu-selection" onClick={() => makeACopyOfBanners()}>
                    <div className="grid-row-menu-selection-wrapper">
                        <img src={copyIcon} alt="copy"/>
                        <span>Make a copy</span>
                    </div>
                </div>
                <div className="grid-row-menu-selection" onClick={(event) => deleteBanners(event, saveId)}>
                    <div className="grid-row-menu-selection-wrapper">
                        <img src={trashIcon} alt="trash"/>
                        <span>Discard</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

function GridRowItem({rowData}) {

    const ref = useRef();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isEditable = useSelector(state => state.recentDesigns.isEditable);

    const [openMenu, setOpenMenu] = useState(false);
    const [toggle, setToggle] = useState(false);
    const [projectName, setProjectName] = useState(String(rowData.template_json_data?.namingConvention));

    useOutsideClick(ref, async () => {
        if (toggle) {
            setToggle(false);
            await sendUpdatedNamingConvention();
        }
    });

    const loadAndNavigate = async (event, saveId) => {
        event.stopPropagation();
        if (!isEditable) {
            try {
                dispatch(setUsersSaveId(saveId));
                await dispatch(ThunkLoadBanners(saveId));
                navigate('/');
                dispatch(selectTemplate(rowData.template_json_data.group));
                dispatch(sideMenuSelect(1));
                dispatch(setIsProjectLoaded(true));
                dispatch(changePersistentNamingConvention(rowData.template_json_data.namingConvention));
            } catch (err) {
                // TODO: An Error messaging system needs to be implemented
                console.log('issue obtaining data');
            }
        }
    }

    const clickingProjectName = (event) => {
        event.stopPropagation();
        setToggle(true);
    };

    const sendUpdatedNamingConvention = async () => {
        setToggle(false);
        if (projectName !== rowData.template_json_data?.namingConvention) {
            const newTemplateData = {...rowData.template_json_data};
            newTemplateData.namingConvention = projectName;
            await dispatch(ThunkUpdateProjectName(rowData.save_id, newTemplateData));
            const response = await dispatch(ThunkLoadRecentDesigns());
            dispatch(setGridRowItems(response));
        }
    }

    const commitProjectName = async (event) => {
        if (event.key === 'Enter' || event.key === 'Escape') {
            await sendUpdatedNamingConvention();
            event.preventDefault();
            event.stopPropagation();
        }
    };


    const openGridMenu = (event) => {
        event.stopPropagation();
        setOpenMenu(!openMenu);
    };

    const determineTimeDisplay = (time) => {
        time.getTime();
        const now = Date.now();
        const difference = now - time.getTime();

        let seconds = Math.floor(difference / 1000);
        let minutes = Math.floor(seconds / 60)
        let hours = Math.floor(minutes / 60);

        seconds = seconds % 60;
        minutes = minutes % 60;
        hours = hours % 60;

        if (hours < 24) {

            let agoTime = '';
            if (hours > 0) {
                agoTime += `${hours}h `
            }
            if (minutes > 0) {
                agoTime += `${minutes}m `
            }
            agoTime += `${seconds < 0 ? '1' : seconds}s ago`

            return agoTime;
        } else {
            return `${time.getMonth() + 1}/${time.getDate()}/${time.getFullYear()} ${time.toLocaleString('en-US', {hour: 'numeric', minute: 'numeric', hour12: true})}`;
        }
    }

    return (<div className="row-contents" onMouseDown={(event) => loadAndNavigate(event, rowData.save_id)}>
            <div className="recent-design-grid-rows">
                <div className="recent-design-grid-row-wrapper first-column">
                    <div className="recent-design-grid-img-wrapper">
                        <img alt="saved-image" src={rowData.thumbnail}/>
                    </div>
                    <div ref={ref} className="recent-design-project-name" onMouseDown={clickingProjectName}>
                        {toggle ? <ProjectNameInput value={projectName} onChange={event => setProjectName(event.target.value)} onBlur={sendUpdatedNamingConvention} onKeyDown={commitProjectName}/> : <span className="project-name-slot">{rowData.template_json_data?.namingConvention ? rowData.template_json_data.namingConvention : 'Unnamed Project'}</span>}
                        <GrEdit/>
                    </div>
                </div>
            </div>
            <div className="recent-design-grid-rows">
                <div className="recent-design-grid-row-wrapper second-column">
                    {rowData.template_json_data?.description?.title.slice(0, -1)}
                </div>
            </div>
            <div className="recent-design-grid-rows">
                <div className="recent-design-grid-row-wrapper third-column">
                    {determineTimeDisplay(new Date(rowData.updated_at || rowData.created_at))}
                </div>
            </div>
            <div className="recent-design-grid-rows">
                <div className="recent-design-grid-row-wrapper fourth-column">
                    {rowData.first_name} {rowData.last_name}
                </div>
            </div>
            <div className="recent-design-grid-rows">
                <div className="recent-design-grid-row-wrapper">
                    <button type="button" onMouseDown={openGridMenu}><img src={threeDots} alt="..."/></button>
                    <GridRowMenu openMenu={openMenu} firstName={rowData.first_name} lastName={rowData.last_name} timeStamp={determineTimeDisplay(new Date(rowData.updated_at || rowData.created_at))} saveId={rowData.save_id} setOpenMenu={setOpenMenu} templateJson={rowData.template_json_data}/>
                </div>
            </div>
        </div>
    )
}

function RecentDesigns() {

    const dispatch = useDispatch();

    const gridRowItems = useSelector(state => state.recentDesigns.gridRowItems);

    useEffect(() => {

        // setting isProjectLoaded to false so that we do not get a pop-up about saving/discarding otherwise.
        // If you load a project throughout the site, then you should set this to true so that you will get a pop-up.
        // When we load recent-design it typically means you are about to load a project
        dispatch(setIsProjectLoaded(false));
        dispatch(setInCreateDesignMode(false));

        let abortController = new AbortController();

        getRecentDesignData().then();

        return () => abortController.abort();

    }, [])

    const getRecentDesignData = async () => {
        const response = await dispatch(ThunkLoadRecentDesigns());
        dispatch(setGridRowItems(response));
    }


    return (
        <div id="recent-design-background">

            <TopBar recentDesign={true}/>

            <div id="main-container">

                <div id="recent-design-grid-title">
                    <h1>RECENT DESIGNS</h1>
                </div>
                <div id="recent-design-grid">
                    <div className="recent-design-grid-headers">Project name</div>
                    <div className="recent-design-grid-headers">Template</div>
                    <div className="recent-design-grid-headers">Edited</div>
                    <div className="recent-design-grid-headers">Creator</div>
                    <div className="recent-design-grid-headers"></div>

                    {gridRowItems.map((rowData) => <GridRowItem key={rowData.save_id} rowData={rowData}/>)}

                </div>

            </div>

        </div>
    )
}

export default RecentDesigns;
