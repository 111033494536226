import {
    LOGO_SRC, LOGO_X_POSITION, LOGO_Y_POSITION, LOGO_SIZE, HEADLINE_TEXT, HEADLINE_FONT_SIZE, HEADLINE_FONT_FAMILY,
    HEADLINE_FONT_COLOR, HEADLINE_X_POSITION, HEADLINE_Y_POSITION, SUB_HEADLINE_TEXT, SUB_HEADLINE_FONT_SIZE, SUB_HEADLINE_FONT_FAMILY,
    SUB_HEADLINE_FONT_COLOR, SUB_HEADLINE_X_POSITION, SUB_HEADLINE_Y_POSITION, CTA_TEXT, CTA_FONT_SIZE,
    CTA_FONT_FAMILY, CTA_FONT_COLOR, CTA_X_POSITION, CTA_Y_POSITION, BG_COLOR_EDIT, SELECT_BANNER, LOGO_SRC_EVERY, LOGO_SIZE_EVERY, HEADLINE_TEXT_EVERY,
    SUB_HEADLINE_TEXT_EVERY, BG_COLOR_EDIT_EVERY, CTA_TEXT_EVERY, SELECT_TEMPLATE, IMAGE_1_SIZE_EVERY, IMAGE_1_SRC_EVERY,
    IMAGE_2_SIZE_EVERY, IMAGE_2_SRC_EVERY, IMAGE_1_SRC, IMAGE_1_SIZE, IMAGE_1_X_POSITION, IMAGE_1_Y_POSITION, IMAGE_2_SRC, IMAGE_2_SIZE,
    IMAGE_2_X_POSITION, IMAGE_2_Y_POSITION, LOGO_X_POSITION_EVERY, LOGO_Y_POSITION_EVERY, IMAGE_1_X_POSITION_EVERY, IMAGE_1_Y_POSITION_EVERY,
    IMAGE_2_X_POSITION_EVERY, IMAGE_2_Y_POSITION_EVERY, HEADLINE_COLOR_EVERY, SUB_HEADLINE_COLOR_EVERY, CTA_COLOR_EVERY,
    BG_IMAGE_SRC_EVERY, BG_IMAGE_SIZE_EVERY, BG_IMAGE_X_POSITION_EVERY, BG_IMAGE_Y_POSITION_EVERY, BG_IMAGE_SRC,
    BG_IMAGE_SIZE, BG_IMAGE_X_POSITION, BG_IMAGE_Y_POSITION, TOGGLE_BANNER, LINKING_URL, NAMING_CONVENTION, BG_IMAGE_ROTATE, BG_IMAGE_ROTATE_EVERY, LOGO_RESET, IMAGE_1_RESET,
    BG_IMAGE_RESET, IMAGE_2_RESET, BG_IMAGE_RESET_EVERY, LOGO_RESET_EVERY, IMAGE_1_RESET_EVERY, IMAGE_2_RESET_EVERY,
    ALL_BANNERS_RESET, INDIVIDUAL_BANNER_RESET, ALL_TEXT_COLOR_INDIVIDUAL, ALL_TEXT_COLOR_EVERY, PROMO_BG_COLOR_EDIT_EVERY,
    PROMO_FONT_COLOR_EVERY, PROMO_DISCOUNT_TEXT_EVERY, PROMO_CODE_TEXT_EVERY, PROMO_CODE_TEXT, PROMO_DISCOUNT_TEXT, LOAD_BANNERS_FROM_DB, ALL_BANNERS_AND_TEMPLATES_RESET, ALL_BUT_SELECTED_RESET_TEMPLATES_BANNERS, PERSISTENT_NAMING_CONVENTION
} from './banner.types';

export const selectBanner = (payload) => {
    return {
        type: SELECT_BANNER,
        payload: payload
    }
};

export const selectTemplate = (payload) => {
    return {
        type: SELECT_TEMPLATE,
        payload: payload
    }
}

export const toggleBanner = (payload) => {
    return {
        type: TOGGLE_BANNER,
        payload: {...payload}
    }
}

export const changeNamingConvention = (payload) => {
    return {
        type: NAMING_CONVENTION,
        payload: payload
    }
}

export const changePersistentNamingConvention = (payload) => {
    return {
        type: PERSISTENT_NAMING_CONVENTION,
        payload: payload
    }
}

export const changeLinkingUrl = (payload) => {
    return {
        type: LINKING_URL,
        payload: payload
    }
}

export const bgImageSrc = (payload) => {
    return {
        type: BG_IMAGE_SRC,
        payload: {...payload}
    };
};

export const bgImageSize = (payload) => {
    return {
        type: BG_IMAGE_SIZE,
        payload: {...payload}
    };
};

export const bgImageRotate = (payload) => {
    return {
        type: BG_IMAGE_ROTATE,
        payload: {...payload}
    };
};

export const bgImageXPosition = (payload) => {
    return {
        type: BG_IMAGE_X_POSITION,
        payload: {...payload}
    };
};

export const bgImageYPosition = (payload) => {
    return {
        type: BG_IMAGE_Y_POSITION,
        payload: {...payload}
    };
};

export const bgImageReset = (payload) => {
    return {
        type: BG_IMAGE_RESET,
        payload: payload
    }
}

export const logoSrc = (payload) => {
    return {
        type: LOGO_SRC,
        payload: {...payload}
    };
};

export const logoSize = (payload) => {
    return {
        type: LOGO_SIZE,
        payload: {...payload}
    };
};

export const logoXPosition = (payload) => {
    return {
        type: LOGO_X_POSITION,
        payload: {...payload}
    };
};

export const logoYPosition = (payload) => {
    return {
        type: LOGO_Y_POSITION,
        payload: {...payload}
    };
};

export const logoReset = (payload) => {
    return {
        type: LOGO_RESET,
        payload: payload
    };
};

export const image1Src = (payload) => {
    return {
        type: IMAGE_1_SRC,
        payload: {...payload}
    };
};

export const image1Size = (payload) => {
    return {
        type: IMAGE_1_SIZE,
        payload: {...payload}
    };
};

export const image1XPosition = (payload) => {
    return {
        type: IMAGE_1_X_POSITION,
        payload: {...payload}
    };
};

export const image1YPosition = (payload) => {
    return {
        type: IMAGE_1_Y_POSITION,
        payload: {...payload}
    };
};

export const image1Reset = (payload) => {
    return {
        type: IMAGE_1_RESET,
        payload: payload
    }
}

export const image2Src = (payload) => {
    return {
        type: IMAGE_2_SRC,
        payload: {...payload}
    };
};

export const image2Size = (payload) => {
    return {
        type: IMAGE_2_SIZE,
        payload: {...payload}
    };
};

export const image2XPosition = (payload) => {
    return {
        type: IMAGE_2_X_POSITION,
        payload: {...payload}
    };
};

export const image2YPosition = (payload) => {
    return {
        type: IMAGE_2_Y_POSITION,
        payload: {...payload}
    };
};

export const image2Reset = (payload) => {
    return {
        type: IMAGE_2_RESET,
        payload: payload
    }
}

export const headlineText = (payload) => {
    return {
        type: HEADLINE_TEXT,
        payload: {...payload}
    };
};

export const headlineFontSize = (payload) => {
    return {
        type: HEADLINE_FONT_SIZE,
        payload: {...payload}
    };
};

export const headlineFontFamily = (payload) => {
    return {
        type: HEADLINE_FONT_FAMILY,
        payload: {...payload}
    };
};

export const headlineFontColor = (payload) => {
    return {
        type: HEADLINE_FONT_COLOR,
        payload: {...payload}
    };
};

export const headlineXPosition = (payload) => {
    return {
        type: HEADLINE_X_POSITION,
        payload: {...payload}
    };
};

export const headlineYPosition = (payload) => {
    return {
        type: HEADLINE_Y_POSITION,
        payload: {...payload}
    };
};

export const subHeadlineText = (payload) => {
    return {
        type: SUB_HEADLINE_TEXT,
        payload: {...payload}
    };
};

export const subHeadlineFontSize = (payload) => {
    return {
        type: SUB_HEADLINE_FONT_SIZE,
        payload: {...payload}
    };
};

export const subHeadlineFontFamily = (payload) => {
    return {
        type: SUB_HEADLINE_FONT_FAMILY,
        payload: {...payload}
    };
};

export const subHeadlineFontColor = (payload) => {
    return {
        type: SUB_HEADLINE_FONT_COLOR,
        payload: {...payload}
    };
};

export const subHeadlineXPosition = (payload) => {
    return {
        type: SUB_HEADLINE_X_POSITION,
        payload: {...payload}
    };
};

export const subHeadlineYPosition = (payload) => {
    return {
        type: SUB_HEADLINE_Y_POSITION,
        payload: {...payload}
    };
};

export const ctaText = (payload) => {
    return {
        type: CTA_TEXT,
        payload: {...payload}
    };
};

export const ctaFontSize = (payload) => {
    return {
        type: CTA_FONT_SIZE,
        payload: {...payload}
    };
};

export const ctaFontFamily = (payload) => {
    return {
        type: CTA_FONT_FAMILY,
        payload: {...payload}
    };
};

export const ctaFontColor = (payload) => {
    return {
        type: CTA_FONT_COLOR,
        payload: {...payload}
    };
};

export const ctaXPosition = (payload) => {
    return {
        type: CTA_X_POSITION,
        payload: {...payload}
    };
};

export const ctaYPosition = (payload) => {
    return {
        type: CTA_Y_POSITION,
        payload: {...payload}
    };
};

export const promoDiscountText = (payload) => {
    return {
        type: PROMO_DISCOUNT_TEXT,
        payload: {...payload}
    };
};

export const promoCodeText = (payload) => {
    return  {
        type: PROMO_CODE_TEXT,
        payload: {...payload}
    };
};

export const bgColorEdit = (payload) => {
    return {
        type: BG_COLOR_EDIT,
        payload: {...payload}
    };
};


export const changeAllTextColorIndividual = (payload) => {
    return {
        type: ALL_TEXT_COLOR_INDIVIDUAL,
        payload: {...payload}
    }
}

export const changeAllTextColorEvery = (payload) => {
    return {
        type: ALL_TEXT_COLOR_EVERY,
        payload: payload
    }
}

export const bgImageSrcEvery = (payload) => {
    return {
        type: BG_IMAGE_SRC_EVERY,
        payload: payload
    };
};


export const bgImageSizeEvery = (payload) => {
    return {
        type: BG_IMAGE_SIZE_EVERY,
        payload: payload
    };
};

export const bgImageRotateEvery = (payload) => {
    return {
        type: BG_IMAGE_ROTATE_EVERY,
        payload: payload
    };
};

export const bgImageXPositionEvery = (payload) => {
    return {
        type: BG_IMAGE_X_POSITION_EVERY,
        payload: payload
    }
}

export const bgImageYPositionEvery = (payload) => {
    return {
        type: BG_IMAGE_Y_POSITION_EVERY,
        payload: payload
    }
}

export const bgImageResetEvery = () => {
    return {
        type: BG_IMAGE_RESET_EVERY
    }
}

export const logoSrcEvery = (payload) => {
    return {
        type: LOGO_SRC_EVERY,
        payload: payload
    };
};

export const logoSizeEvery = (payload) => {
    return {
        type: LOGO_SIZE_EVERY,
        payload: payload
    };
};

export const logoXPositionEvery = (payload) => {
    return {
        type: LOGO_X_POSITION_EVERY,
        payload: payload
    }
}

export const logoYPositionEvery = (payload) => {
    return {
        type: LOGO_Y_POSITION_EVERY,
        payload: payload
    }
}

export const logoResetEvery = () => {
    return {
        type: LOGO_RESET_EVERY
    }
}

export const image1SrcEvery = (payload) => {
    return {
        type: IMAGE_1_SRC_EVERY,
        payload: payload
    };
};


export const image1SizeEvery = (payload) => {
    return {
        type: IMAGE_1_SIZE_EVERY,
        payload: payload
    };
};

export const image1XPositionEvery = (payload) => {
    return {
        type: IMAGE_1_X_POSITION_EVERY,
        payload: payload
    }
}

export const image1YPositionEvery = (payload) => {
    return {
        type: IMAGE_1_Y_POSITION_EVERY,
        payload: payload
    }
}

export const image1ResetEvery = () => {
    return {
        type: IMAGE_1_RESET_EVERY
    }
}

export const image2SrcEvery = (payload) => {
    return {
        type: IMAGE_2_SRC_EVERY,
        payload: payload
    };
};


export const image2SizeEvery = (payload) => {
    return {
        type: IMAGE_2_SIZE_EVERY,
        payload: payload
    };
};

export const image2XPositionEvery = (payload) => {
    return {
        type: IMAGE_2_X_POSITION_EVERY,
        payload: payload
    }
}

export const image2YPositionEvery = (payload) => {
    return {
        type: IMAGE_2_Y_POSITION_EVERY,
        payload: payload
    }
}

export const image2ResetEvery = () => {
    return {
        type: IMAGE_2_RESET_EVERY
    }
}

export const headlineTextEvery = (payload) => {
    return {
        type: HEADLINE_TEXT_EVERY,
        payload: payload
    };
};

export const headlineColorEvery = (payload) => {
    return {
        type: HEADLINE_COLOR_EVERY,
        payload: payload
    };
};


export const subHeadlineTextEvery = (payload) => {
    return {
        type: SUB_HEADLINE_TEXT_EVERY,
        payload: payload
    };
};

export const subHeadlineColorEvery = (payload) => {
    return {
        type: SUB_HEADLINE_COLOR_EVERY,
        payload: payload
    };
};

export const ctaTextEvery = (payload) => {
    return {
        type: CTA_TEXT_EVERY,
        payload: payload
    };
};

export const ctaColorEvery = (payload) => {
    return {
        type: CTA_COLOR_EVERY,
        payload: payload
    };
};

export const promoBgColorEditEvery = (payload) => {
    return {
        type: PROMO_BG_COLOR_EDIT_EVERY,
        payload: payload
    }
}

export const promoFontColorEvery = (payload) => {
    return {
        type: PROMO_FONT_COLOR_EVERY,
        payload: payload
    }
}

export const promoDiscountTextEvery = (payload) => {
    return {
        type: PROMO_DISCOUNT_TEXT_EVERY,
        payload: payload
    }
}

export const promoCodeTextEvery = (payload) => {
    return {
        type: PROMO_CODE_TEXT_EVERY,
        payload: payload
    }
}

export const bgColorEditEvery = (payload) => {
    return {
        type: BG_COLOR_EDIT_EVERY,
        payload: payload
    };
};

export const allBannersReset = () => {
    return {
        type: ALL_BANNERS_RESET
    }
}

export const allBannersAndTemplatesReset = () => {
    return {
        type: ALL_BANNERS_AND_TEMPLATES_RESET
    }
}

export const allButSelectedResetTemplatesBanners = (payload) => {
    return {
        type: ALL_BUT_SELECTED_RESET_TEMPLATES_BANNERS,
        payload: {...payload}
    }
}

export const individualBannerReset = () => {
    return {
        type: INDIVIDUAL_BANNER_RESET
    }
}

export const loadBannersFromDB = (payload) => {
    return {
        type: LOAD_BANNERS_FROM_DB,
        payload: {...payload}
    }
}
