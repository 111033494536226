import React, {useEffect, useRef} from "react";
import {setProjectNameEditable} from '../../redux/RecentDesigns/RecentDesigns.actions';
import {useDispatch} from 'react-redux';

function ProjectNameInput({value, onChange, onBlur, onKeyDown}) {

    const ref = useRef(null);

    const dispatch = useDispatch();

    useEffect(() => {
        ref.current.focus();
        dispatch(setProjectNameEditable(true));

        return () => {
            dispatch(setProjectNameEditable(false));
        };
    },[])

    return (
        <input ref={ref} type="text" className="project-name-slot" spellCheck={false} value={value} onChange={onChange} onBlur={() => onBlur()} onKeyDown={onKeyDown} />
    )
}

export default ProjectNameInput;
