import produce from 'immer';
import {
    ALL_BANNERS_RESET, BG_COLOR_EDIT, BG_COLOR_EDIT_EVERY, BG_IMAGE_RESET, BG_IMAGE_RESET_EVERY, BG_IMAGE_ROTATE, BG_IMAGE_ROTATE_EVERY, BG_IMAGE_SIZE, BG_IMAGE_SIZE_EVERY,
    BG_IMAGE_SRC, BG_IMAGE_SRC_EVERY, BG_IMAGE_X_POSITION, BG_IMAGE_X_POSITION_EVERY, BG_IMAGE_Y_POSITION, BG_IMAGE_Y_POSITION_EVERY,
    HEADLINE_COLOR_EVERY, HEADLINE_FONT_COLOR, HEADLINE_FONT_FAMILY, HEADLINE_FONT_SIZE, HEADLINE_TEXT, HEADLINE_TEXT_EVERY, HEADLINE_X_POSITION, HEADLINE_Y_POSITION,
    SUB_HEADLINE_COLOR_EVERY, SUB_HEADLINE_FONT_COLOR, SUB_HEADLINE_FONT_FAMILY, SUB_HEADLINE_FONT_SIZE, SUB_HEADLINE_TEXT, SUB_HEADLINE_TEXT_EVERY, SUB_HEADLINE_X_POSITION, SUB_HEADLINE_Y_POSITION,
    CTA_COLOR_EVERY, CTA_FONT_COLOR, CTA_FONT_FAMILY, CTA_FONT_SIZE, CTA_TEXT, CTA_TEXT_EVERY, CTA_X_POSITION, CTA_Y_POSITION,
    IMAGE_1_RESET, IMAGE_1_RESET_EVERY, IMAGE_1_SIZE, IMAGE_1_SIZE_EVERY, IMAGE_1_SRC, IMAGE_1_SRC_EVERY, IMAGE_1_X_POSITION, IMAGE_1_X_POSITION_EVERY, IMAGE_1_Y_POSITION, IMAGE_1_Y_POSITION_EVERY, IMAGE_2_RESET, IMAGE_2_RESET_EVERY,
    IMAGE_2_SIZE, IMAGE_2_SIZE_EVERY, IMAGE_2_SRC, IMAGE_2_SRC_EVERY, IMAGE_2_X_POSITION, IMAGE_2_X_POSITION_EVERY, IMAGE_2_Y_POSITION, IMAGE_2_Y_POSITION_EVERY, INDIVIDUAL_BANNER_RESET,
    LINKING_URL, LOGO_RESET, LOGO_RESET_EVERY, LOGO_SIZE, LOGO_SIZE_EVERY, LOGO_SRC, LOGO_SRC_EVERY, LOGO_X_POSITION, LOGO_X_POSITION_EVERY, LOGO_Y_POSITION, LOGO_Y_POSITION_EVERY,
    NAMING_CONVENTION, SELECT_BANNER, SELECT_TEMPLATE, TOGGLE_BANNER, ALL_TEXT_COLOR_INDIVIDUAL, ALL_TEXT_COLOR_EVERY, PROMO_BG_COLOR_EDIT_EVERY,
    PROMO_FONT_COLOR_EVERY, PROMO_DISCOUNT_TEXT, PROMO_CODE_TEXT, PROMO_DISCOUNT_TEXT_EVERY, PROMO_CODE_TEXT_EVERY, LOAD_BANNERS_FROM_DB, ALL_BANNERS_AND_TEMPLATES_RESET, ALL_BUT_SELECTED_RESET_TEMPLATES_BANNERS, PERSISTENT_NAMING_CONVENTION
} from './banner.types';
import templateALogoBlack from '../../assets/images/template_A/logo_black.png';
import templateALogoWhite from '../../assets/images/template_A/logo_white.png';
import templateAImage from '../../assets/images/template_A/img_1.png';
import templateBLogoBlack from '../../assets/images/template_B/logo_black.png';
import templateBLogoWhite from '../../assets/images/template_B/logo_white.png';
import templateBImage from '../../assets/images/template_B/img_1.png';
import templateCLogoBlack from '../../assets/images/template_C/logo_black.png';
import templateCLogoWhite from '../../assets/images/template_C/logo_white.png';
import templateCImage from '../../assets/images/template_C/img_1.png';
import templateCbgImage from '../../assets/images/template_C/bg_img.png';
import templateDLogoBlack from '../../assets/images/template_D/logo_black.png';
import templateDLogoWhite from '../../assets/images/template_D/logo_white.png';
import templateDImageOne from '../../assets/images/template_D/img_1.png';
import templateDImageTwo from '../../assets/images/template_D/img_2.png';
import templateELogoBlack from '../../assets/images/template_E/logo_black.png';
import templateELogoWhite from '../../assets/images/template_E/logo_white.png';
import templateEImageOne from '../../assets/images/template_E/img_1.png';


const INITIAL_STATE = {
    selectedTemplate: 'A',
    selectedTemplateInDesignMode: null,
    selectedBanner: 0,
    persistentNamingConvention: '',
    ctaList: [
        'Shop Now', 'Shop Deals', 'Shop the Sale', "Today's Deals", 'Shop The Collection', 'Shop The Look',
        'Shop Trend', 'Check it Out', 'Shop New Arrivals', 'Buy the look'
    ],
    templates: {
        byId: {
            'A': {
                id: 1,
                group: 'A',
                namingConvention: '',
                linkingUrl: '',
                linkingUrlPlaceholder: 'Ex: https://www.clinique.com',
                bgColorAll: '#ddead8',
                bgImageSrcAll: null,
                bgImageScaleAll: null,
                bgImageRotateAll: null,
                bgImageXPositionAll: null,
                bgImageYPositionAll: null,
                logoSrcAll: templateALogoBlack,
                logoScaleAll: 1.0,
                logoXPositionAll: 0,
                logoYPositionAll: 0,
                image1SrcAll: templateAImage,
                image1ScaleAll: 1.0,
                image1XPositionAll: 0,
                image1YPositionAll: 0,
                image2SrcAll: null,
                image2ScaleAll: null,
                image2XPositionAll: null,
                image2YPositionAll: null,
                AllTextColor: '#000000',
                headlineTextAll: '',
                headlineTextColorAll: '#000000',
                subHeadlineTextAll: '',
                subHeadlineTextColorAll: '#000000',
                ctaTextAll: '',
                ctaSelection: 0,
                ctaTextColorAll: '#000000',
                description: {
                    title: 'Branding:',
                    info: 'Supports primary branding initiatives, brand awareness & product launches',
                    features: ['Logo', 'Primary image', 'Background color', 'Headline', 'Sub Headline', 'CTA']
                }
            },
            'B': {
                id: 2,
                group: 'B',
                namingConvention: '',
                linkingUrl: '',
                linkingUrlPlaceholder: 'Ex: https://www.clinique.com',
                bgColorAll: '#d1d0cc',
                bgImageSrcAll: null,
                bgImageScaleAll: null,
                bgImageRotateAll: null,
                bgImageXPositionAll: null,
                bgImageYPositionAll: null,
                logoSrcAll: templateBLogoBlack,
                logoScaleAll: 1.0,
                logoXPositionAll: 0,
                logoYPositionAll: 0,
                image1SrcAll: templateBImage,
                image1ScaleAll: 1.0,
                image1XPositionAll: 0,
                image1YPositionAll: 0,
                image2SrcAll: null,
                image2ScaleAll: null,
                image2XPositionAll: null,
                image2YPositionAll: null,
                AllTextColor: '#000000',
                headlineTextAll: '',
                headlineTextColorAll: '#000000',
                subHeadlineTextAll: '',
                subHeadlineTextColorAll: '#000000',
                ctaTextAll: '',
                ctaSelection: 0,
                ctaTextColorAll: '#000000',
                description: {
                    title: 'Branding:',
                    info: 'Supports primary branding initiatives, brand awareness & product launches',
                    features: ['Logo', 'Primary image', 'Background color', 'Headline', 'Sub Headline', 'CTA']
                }
            },
            'C': {
                id: 3,
                group: 'C',
                namingConvention: '',
                linkingUrl: '',
                linkingUrlPlaceholder: 'Ex: https://www.clinique.com',
                bgColorAll: '#FFFFFF',
                bgImageSrcAll: templateCbgImage,
                bgImageScaleAll: 1.0,
                bgImageRotateAll: 0,
                bgImageXPositionAll: 0,
                bgImageYPositionAll: 0,
                logoSrcAll: templateCLogoBlack,
                logoScaleAll: 1.0,
                logoXPositionAll: 0,
                logoYPositionAll: 0,
                image1SrcAll: templateCImage,
                image1ScaleAll: 1.0,
                image1XPositionAll: 0,
                image1YPositionAll: 0,
                image2SrcAll: null,
                image2ScaleAll: null,
                image2XPositionAll: null,
                image2YPositionAll: null,
                AllTextColor: '#000000',
                headlineTextAll: '',
                headlineTextColorAll: '#000000',
                subHeadlineTextAll: '',
                subHeadlineTextColorAll: '#000000',
                ctaTextAll: '',
                ctaSelection: 0,
                ctaTextColorAll: '#000000',
                description: {
                    title: 'Gift with Purchase:',
                    info: 'Supports additional imagery for items that qualify for Gifts with purchase.',
                    features: ['Logo', 'Primary image', 'Secondary image', 'Background color', 'Headline', 'Sub Headline', 'CTA']
                }
            },
            'D': {
                id: 4,
                group: 'D',
                namingConvention: '',
                linkingUrl: '',
                linkingUrlPlaceholder: 'Ex: https://www.clinique.com',
                bgColorAll: '#f0dddd',
                bgImageSrcAll: null,
                bgImageScaleAll: 1.0,
                bgImageRotateAll: 0,
                bgImageXPositionAll: 0,
                bgImageYPositionAll: 0,
                logoSrcAll: templateDLogoBlack,
                logoScaleAll: 1.0,
                logoXPositionAll: 0,
                logoYPositionAll: 0,
                image1SrcAll: templateDImageOne,
                image1ScaleAll: 1.0,
                image1XPositionAll: 0,
                image1YPositionAll: 0,
                image2SrcAll: templateDImageTwo,
                image2ScaleAll: 1.0,
                image2XPositionAll: 0,
                image2YPositionAll: 0,
                AllTextColor: '#000000',
                headlineTextAll: '',
                headlineTextColorAll: '#000000',
                subHeadlineTextAll: '',
                subHeadlineTextColorAll: '#000000',
                ctaTextAll: '',
                ctaSelection: 0,
                ctaTextColorAll: '#000000',
                description: {
                    title: 'Gift with Purchase:',
                    info: 'Supports additional imagery for items that qualify for Gifts with purchase.',
                    features: ['Logo', 'Primary image', 'Secondary image', 'Background color', 'Headline', 'Sub Headline', 'CTA']
                }
            },
            'E': {
                id: 5,
                group: 'E',
                namingConvention: '',
                linkingUrl: '',
                linkingUrlPlaceholder: 'Ex: https://www.clinique.com',
                bgColorAll: '#D3CFCD',
                promoBgColorAll: '#85817F',
                bgImageSrcAll: null,
                bgImageScaleAll: 1.0,
                bgImageRotateAll: 0,
                bgImageXPositionAll: 0,
                bgImageYPositionAll: 0,
                logoSrcAll: templateELogoBlack,
                logoScaleAll: 1.0,
                logoXPositionAll: 0,
                logoYPositionAll: 0,
                image1SrcAll: templateEImageOne,
                image1ScaleAll: 1.0,
                image1XPositionAll: 0,
                image1YPositionAll: 0,
                image2SrcAll: null,
                image2ScaleAll: null,
                image2XPositionAll: null,
                image2YPositionAll: null,
                AllTextColor: '#000000',
                promoAllTextColor: '#FFFFFF',
                promoDiscountText: 15,
                promoCodeText: 'VIP',
                headlineTextAll: '',
                headlineTextColorAll: '#000000',
                subHeadlineTextAll: '',
                subHeadlineTextColorAll: '#000000',
                ctaTextAll: '',
                ctaSelection: 0,
                ctaTextColorAll: '#000000',
                description: {
                    title: 'Gift with Purchase:',
                    info: 'Supports additional imagery for items that qualify for Gifts with purchase.',
                    features: ['Logo', 'Primary image', 'Secondary image', 'Background color', 'Headline', 'Sub Headline', 'CTA']
                }
            }
        },
        allIds: ['A', 'B', 'C', 'D', 'E']
    },
    banners: {
        byId: {
            1: {
                id: 1,
                enabled: true,
                template: 'A',
                width: 300,
                height: 250,
                bgColor: '#ddead8',
                bgImage: null,
                allTextColor: '#000000',
                logo: {
                    width: 144,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 50,
                    y: 15,
                    src: templateALogoBlack
                },
                image_1: {
                    width: 221,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 55,
                    y: 88,
                    src: templateAImage
                },
                image_2: null,
                headline: {
                    text: "Moisture Surge™",
                    fontSize: 25,
                    fontFamily: 'Macys Sans Bold',
                    fontColor: '#000000',
                    textAlign: 'center',
                    multiLine: false,
                    x: 50,
                    y: 28
                },
                subHeadline: {
                    text: "100 hours of hydration.",
                    fontSize: 14,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'center',
                    multiLine: false,
                    x: 50,
                    y: 38
                },
                cta: {
                    text: "Shop Now",
                    fontSize: 14,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'center',
                    x: 50,
                    y: 47
                }
            },
            2: {
                id: 2,
                enabled: true,
                template: 'A',
                width: 1280,
                height: 160,
                bgColor: '#ddead8',
                bgImage: null,
                allTextColor: '#000000',
                logo: {
                    width: 216,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 15.5,
                    y: 52,
                    src: templateALogoBlack
                },
                image_1: {
                    width: 286,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 37.5,
                    y: 74,
                    src: templateAImage
                },
                image_2: null,
                headline: {
                    text: "Clinique Moisture Surge™",
                    fontSize: 35,
                    fontFamily: 'Macys Sans Bold',
                    fontColor: '#000000',
                    textAlign: 'center',
                    multiLine: false,
                    x: 66,
                    y: 32
                },
                subHeadline: {
                    text: "100 hours of hydration.",
                    fontSize: 14,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'center',
                    multiLine: false,
                    x: 66,
                    y: 55
                },
                cta: {
                    text: "Shop Now",
                    fontSize: 14,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'center',
                    x: 66,
                    y: 71
                }
            },
            3: {
                id: 3,
                enabled: true,
                template: 'A',
                width: 1280,
                height: 50,
                bgColor: '#ddead8',
                bgImage: null,
                allTextColor: '#000000',
                logo: {
                    width: 131,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 40,
                    y: 52,
                    src: templateALogoBlack
                },
                image_1: {
                    width: 106,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 49.8,
                    y: 83,
                    src: templateAImage
                },
                image_2: null,
                headline: null,
                subHeadline: null,
                cta: {
                    text: "Shop Now",
                    fontSize: 14,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'left',
                    x: 53.5,
                    y: 51
                }
            },
            4: {
                id: 4,
                enabled: true,
                template: 'A',
                width: 954,
                height: 118,
                bgColor: '#ddead8',
                bgImage: null,
                allTextColor: '#000000',
                logo: {
                    width: 184,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 15.5,
                    y: 52,
                    src: templateALogoBlack
                },
                image_1: {
                    width: 245,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 39,
                    y: 83,
                    src: templateAImage
                },
                image_2: null,
                headline: {
                    text: "Clinique Moisture Surge™",
                    fontSize: 35,
                    fontFamily: 'Macys Sans Bold',
                    fontColor: '#000000',
                    textAlign: 'center',
                    multiLine: false,
                    x: 72,
                    y: 32
                },
                subHeadline: {
                    text: "100 hours of hydration.",
                    fontSize: 14,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'center',
                    multiLine: false,
                    x: 72,
                    y: 58
                },
                cta: {
                    text: "Shop Now",
                    fontSize: 14,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'center',
                    x: 72,
                    y: 76
                }
            },
            5: {
                id: 5,
                enabled: true,
                template: 'A',
                width: 335,
                height: 94,
                bgColor: '#ddead8',
                bgImage: null,
                allTextColor: '#000000',
                logo: {
                    width: 131,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 30,
                    y: 31,
                    src: templateALogoBlack
                },
                image_1: {
                    width: 190,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 79,
                    y: 81,
                    src: templateAImage
                },
                image_2: null,
                headline: null,
                subHeadline: {
                    text: "100 hours of hydration.",
                    fontSize: 14,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'center',
                    multiLine: false,
                    x: 31,
                    y: 58
                },
                cta: {
                    text: "Shop Now",
                    fontSize: 14,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'center',
                    x: 31,
                    y: 78
                }
            },
            6: {
                id: 6,
                enabled: true,
                template: 'A',
                width: 688,
                height: 536,
                bgColor: '#ddead8',
                bgImage: null,
                allTextColor: '#000000',
                logo: {
                    width: 244,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'left bottom',
                    x: 26,
                    y: 16.5,
                    src: templateALogoBlack
                },
                image_1: {
                    width: 510,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 55,
                    y: 79,
                    src: templateAImage
                },
                image_2: null,
                headline: {
                    text: "Moisture Surge™",
                    fontSize: 56,
                    fontFamily: 'Macys Sans Bold',
                    fontColor: '#000000',
                    textAlign: 'left',
                    multiLine: false,
                    x: 8,
                    y: 26
                },
                subHeadline: {
                    text: "100 hours of hydration.",
                    fontSize: 22,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'left',
                    multiLine: false,
                    x: 8,
                    y: 36.5
                },
                cta: {
                    text: "Shop Now",
                    fontSize: 22,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'left',
                    x: 8,
                    y: 44
                }
            },
            7: {
                id: 7,
                enabled: true,
                template: 'A',
                width: 1280,
                height: 240,
                bgColor: '#ddead8',
                bgImage: null,
                allTextColor: '#000000',
                logo: {
                    width: 216,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 15.5,
                    y: 52,
                    src: templateALogoBlack
                },
                image_1: {
                    width: 321,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 40.2,
                    y: 54,
                    src: templateAImage
                },
                image_2: null,
                headline: {
                    text: "Clinique Moisture Surge™",
                    fontSize: 35,
                    fontFamily: 'Macys Sans Bold',
                    fontColor: '#000000',
                    textAlign: 'center',
                    multiLine: false,
                    x: 72.6,
                    y: 39
                },
                subHeadline: {
                    text: "100 hours of hydration.",
                    fontSize: 18,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'center',
                    multiLine: false,
                    x: 72.4,
                    y: 55
                },
                cta: {
                    text: "Shop Now",
                    fontSize: 18,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'center',
                    x: 72.4,
                    y: 71
                }
            },
            8: {
                id: 8,
                enabled: true,
                template: 'A',
                width: 559,
                height: 133,
                bgColor: '#ddead8',
                bgImage: null,
                allTextColor: '#000000',
                logo: {
                    width: 124,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 17.5,
                    y: 51,
                    src: templateALogoBlack
                },
                image_1: {
                    width: 175,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 43,
                    y: 54,
                    src: templateAImage
                },
                image_2: null,
                headline: {
                    text: "Clinique Moisture Surge™",
                    fontSize: 20,
                    fontFamily: 'Macys Sans Bold',
                    fontColor: '#000000',
                    textAlign: 'center',
                    multiLine: false,
                    x: 76,
                    y: 31
                },
                subHeadline: {
                    text: "100 hours of hydration.",
                    fontSize: 14,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'center',
                    multiLine: false,
                    x: 76,
                    y: 52
                },
                cta: {
                    text: "Shop Now",
                    fontSize: 14,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'center',
                    x: 76,
                    y: 74
                }
            },
            9: {
                id: 9,
                enabled: true,
                template: 'A',
                width: 1280,
                height: 80,
                bgColor: '#000000',
                bgImage: null,
                allTextColor: '#000000',
                logo: {
                    width: 131,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 49.4,
                    y: 52,
                    src: templateALogoWhite
                },
                image_1: null,
                image_2: null,
                headline: null,
                subHeadline: null,
                cta: null,
            },
            10: {
                id: 10,
                enabled: true,
                template: 'A',
                width: 559,
                height: 80,
                bgColor: '#000000',
                bgImage: null,
                allTextColor: '#000000',
                logo: {
                    width: 124,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 50,
                    y: 51,
                    src: templateALogoWhite
                },
                image_1: null,
                image_2: null,
                headline: null,
                subHeadline: null,
                cta: null,
            },
            11: {
                id: 11,
                enabled: true,
                template: 'B',
                width: 300,
                height: 250,
                bgColor: '#d1d0cc',
                bgImage: null,
                allTextColor: '#000000',
                logo: {
                    width: 113,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'left bottom',
                    x: 23,
                    y: 22,
                    src: templateBLogoBlack
                },
                image_1: {
                    width: 152,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 76,
                    y: 62,
                    src: templateBImage
                },
                image_2: null,
                headline: {
                    text: "Introducing \r\nthe new collection",
                    fontSize: 18,
                    fontFamily: 'ITC Avant Garde Gothic STD Md',
                    fontColor: '#000000',
                    textAlign: 'left',
                    multiLine: true,
                    x: 4,
                    y: 49
                },
                subHeadline: null,
                cta: {
                    text: "Shop Now",
                    fontSize: 14,
                    fontFamily: 'ITC Avant Garde Gothic STD Demi',
                    fontColor: '#000000',
                    textAlign: 'left',
                    x: 4,
                    y: 64
                }
            },
            12: {
                id: 12,
                enabled: true,
                template: 'B',
                width: 1280,
                height: 160,
                bgColor: '#d1d0cc',
                bgImage: null,
                allTextColor: '#000000',
                logo: {
                    width: 152,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 10.7,
                    y: 51,
                    src: templateBLogoBlack
                },
                image_1: {
                    width: 112,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 32.6,
                    y: 71,
                    src: templateBImage
                },
                image_2: null,
                headline: {
                    text: "Introducing the new collection",
                    fontSize: 35,
                    fontFamily: 'ITC Avant Garde Gothic STD Md',
                    fontColor: '#000000',
                    textAlign: 'left',
                    multiLine: false,
                    x: 45.6,
                    y: 36
                },
                subHeadline: {
                    text: "Signature sleeves on every piece.",
                    fontSize: 18,
                    fontFamily: 'ITC Avant Garde Gothic STD Md',
                    fontColor: '#000000',
                    textAlign: 'left',
                    multiLine: false,
                    x: 45.6,
                    y: 62
                },
                cta: {
                    text: "Shop Now",
                    fontSize: 18,
                    fontFamily: 'ITC Avant Garde Gothic STD Demi',
                    fontColor: '#000000',
                    textAlign: 'right',
                    x: 96.3,
                    y: 48
                }
            },
            13: {
                id: 13,
                enabled: true,
                template: 'B',
                width: 1280,
                height: 50,
                bgColor: '#d1d0cc',
                bgImage: null,
                allTextColor: '#000000',
                logo: {
                    width: 67,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 42.6,
                    y: 52,
                    src: templateBLogoBlack
                },
                image_1: {
                    width: 40,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 49.5,
                    y: 83,
                    src: templateBImage
                },
                image_2: null,
                headline: null,
                subHeadline: null,
                cta: {
                    text: "Shop Now",
                    fontSize: 14,
                    fontFamily: 'ITC Avant Garde Gothic STD Demi',
                    fontColor: '#000000',
                    textAlign: 'left',
                    x: 53.5,
                    y: 51
                }
            },
            14: {
                id: 14,
                enabled: true,
                template: 'B',
                width: 954,
                height: 118,
                bgColor: '#d1d0cc',
                bgImage: null,
                allTextColor: '#000000',
                logo: {
                    width: 120,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 12.5,
                    y: 51,
                    src: templateBLogoBlack
                },
                image_1: {
                    width: 78,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 29.7,
                    y: 70,
                    src: templateBImage
                },
                image_2: null,
                headline: {
                    text: "Introducing the new collection",
                    fontSize: 25,
                    fontFamily: 'ITC Avant Garde Gothic STD Md',
                    fontColor: '#000000',
                    textAlign: 'left',
                    multiLine: false,
                    x: 43,
                    y: 38
                },
                subHeadline: {
                    text: "Signature sleeves on every piece.",
                    fontSize: 14,
                    fontFamily: 'ITC Avant Garde Gothic STD Md',
                    fontColor: '#000000',
                    textAlign: 'left',
                    multiLine: false,
                    x: 43,
                    y: 62
                },
                cta: {
                    text: "Shop Now",
                    fontSize: 14,
                    fontFamily: 'ITC Avant Garde Gothic STD Demi',
                    fontColor: '#000000',
                    textAlign: 'right',
                    x: 94.9,
                    y: 48
                }
            },
            15: {
                id: 15,
                enabled: true,
                template: 'B',
                width: 335,
                height: 94,
                bgColor: '#d1d0cc',
                bgImage: null,
                allTextColor: '#000000',
                logo: {
                    width: 73,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 16,
                    y: 47,
                    src: templateBLogoBlack
                },
                image_1: {
                    width: 63,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 42,
                    y: 71,
                    src: templateBImage
                },
                image_2: null,
                headline: {
                    text: "Introducing the\r\nnew collection.",
                    fontSize: 18,
                    fontFamily: 'ITC Avant Garde Gothic STD Md',
                    fontColor: '#000000',
                    textAlign: 'left',
                    multiLine: true,
                    x: 55,
                    y: 34
                },
                subHeadline: null,
                cta: {
                    text: "Shop Now",
                    fontSize: 12,
                    fontFamily: 'ITC Avant Garde Gothic STD Demi',
                    fontColor: '#000000',
                    textAlign: 'left',
                    x: 55,
                    y: 74
                }
            },
            16: {
                id: 16,
                enabled: true,
                template: 'B',
                width: 688,
                height: 536,
                bgColor: '#d1d0cc',
                bgImage: null,
                allTextColor: '#000000',
                logo: {
                    width: 162,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'left bottom',
                    x: 18,
                    y: 16,
                    src: templateBLogoBlack
                },
                image_1: {
                    width: 299,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 77,
                    y: 57,
                    src: templateBImage
                },
                image_2: null,
                headline: {
                    text: "Introducing\r\nthe new collection",
                    fontSize: 36,
                    fontFamily: 'ITC Avant Garde Gothic STD Md',
                    fontColor: '#000000',
                    textAlign: 'left',
                    multiLine: true,
                    x: 5.8,
                    y: 36
                },
                subHeadline: {
                    text: "Signature sleeves on every piece.",
                    fontSize: 22,
                    fontFamily: 'ITC Avant Garde Gothic STD Md',
                    fontColor: '#000000',
                    textAlign: 'left',
                    multiLine: false,
                    x: 5.8,
                    y: 47.5
                },
                cta: {
                    text: "Shop Now",
                    fontSize: 18,
                    fontFamily: 'ITC Avant Garde Gothic STD Demi',
                    fontColor: '#000000',
                    textAlign: 'left',
                    x: 5.8,
                    y: 58
                }
            },
            17: {
                id: 17,
                enabled: true,
                template: 'B',
                width: 1280,
                height: 240,
                bgColor: '#d1d0cc',
                bgImage: null,
                allTextColor: '#000000',
                logo: {
                    width: 211,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 15.8,
                    y: 52,
                    src: templateBLogoBlack
                },
                image_1: {
                    width: 120,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 40.2,
                    y: 52,
                    src: templateBImage
                },
                image_2: null,
                headline: {
                    text: "Introducing the new collection",
                    fontSize: 35,
                    fontFamily: 'ITC Avant Garde Gothic STD Md',
                    fontColor: '#000000',
                    textAlign: 'left',
                    multiLine: false,
                    x: 55,
                    y: 39
                },
                subHeadline: {
                    text: "Signature sleeves on every piece.",
                    fontSize: 18,
                    fontFamily: 'ITC Avant Garde Gothic STD Md',
                    fontColor: '#000000',
                    textAlign: 'left',
                    multiLine: false,
                    x: 55,
                    y: 55
                },
                cta: {
                    text: "Shop Now",
                    fontSize: 18,
                    fontFamily: 'ITC Avant Garde Gothic STD Demi',
                    fontColor: '#000000',
                    textAlign: 'left',
                    x: 55,
                    y: 71
                }
            },
            18: {
                id: 18,
                enabled: true,
                template: 'B',
                width: 559,
                height: 133,
                bgColor: '#d1d0cc',
                bgImage: null,
                allTextColor: '#000000',
                logo: {
                    width: 124,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 17.5,
                    y: 51,
                    src: templateBLogoBlack
                },
                image_1: {
                    width: 85,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 40,
                    y: 68,
                    src: templateBImage
                },
                image_2: null,
                headline: {
                    text: "Introducing the new collection",
                    fontSize: 20,
                    fontFamily: 'ITC Avant Garde Gothic STD Md',
                    fontColor: '#000000',
                    textAlign: 'left',
                    multiLine: false,
                    x: 49,
                    y: 31
                },
                subHeadline: {
                    text: "Signature sleeves on every piece.",
                    fontSize: 14,
                    fontFamily: 'ITC Avant Garde Gothic STD Md',
                    fontColor: '#000000',
                    textAlign: 'left',
                    multiLine: false,
                    x: 49,
                    y: 52
                },
                cta: {
                    text: "Shop Now",
                    fontSize: 14,
                    fontFamily: 'ITC Avant Garde Gothic STD Demi',
                    fontColor: '#000000',
                    textAlign: 'left',
                    x: 49,
                    y: 74
                }
            },
            19: {
                id: 19,
                enabled: true,
                template: 'B',
                width: 1280,
                height: 80,
                bgColor: '#000000',
                bgImage: null,
                allTextColor: '#000000',
                logo: {
                    width: 95,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 50,
                    y: 52,
                    src: templateBLogoWhite
                },
                image_1: null,
                image_2: null,
                headline: null,
                subHeadline: null,
                cta: null,
            },
            20: {
                id: 20,
                enabled: true,
                template: 'B',
                width: 559,
                height: 80,
                bgColor: '#000000',
                bgImage: null,
                allTextColor: '#000000',
                logo: {
                    width: 100,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 50,
                    y: 51,
                    src: templateBLogoWhite
                },
                image_1: null,
                image_2: null,
                headline: null,
                subHeadline: null,
                cta: null,
            },
            21: {
                id: 21,
                enabled: true,
                template: 'C',
                width: 300,
                height: 250,
                bgColor: '#ffffff',
                allTextColor: '#000000',
                bgImage: {
                    width: 590,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 40,
                    y: 159,
                    src: templateCbgImage
                },
                logo: {
                    width: 131,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 50,
                    y: 15,
                    src: templateCLogoBlack
                },
                image_1: {
                    width: 203,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 50,
                    y: 99,
                    src: templateCImage
                },
                image_2: null,
                headline: {
                    text: "Best & Brightest",
                    fontSize: 25,
                    fontFamily: 'Macys Sans Bold',
                    fontColor: '#000000',
                    textAlign: 'center',
                    multiLine: false,
                    x: 50,
                    y: 28
                },
                subHeadline: {
                    text: "yours for $49.50 with any $35 purchase",
                    fontSize: 14,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'center',
                    multiLine: false,
                    x: 50,
                    y: 38
                },
                cta: {
                    text: "Shop Now",
                    fontSize: 14,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'center',
                    x: 50,
                    y: 47
                }
            },
            22: {
                id: 22,
                enabled: true,
                template: 'C',
                width: 1280,
                height: 160,
                bgColor: '#ffffff',
                allTextColor: '#000000',
                bgImage: {
                    width: 742,
                    height: 'auto',
                    scale: 1,
                    rotate: 42,
                    align: 'center',
                    x: 22.5,
                    y: 142,
                    src: templateCbgImage
                },
                logo: {
                    width: 216,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 15.5,
                    y: 52,
                    src: templateCLogoBlack
                },
                image_1: {
                    width: 247,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 41,
                    y: 87,
                    src: templateCImage
                },
                image_2: null,
                headline: {
                    text: "Clinique's Best & Brightest",
                    fontSize: 35,
                    fontFamily: 'Macys Sans Bold',
                    fontColor: '#000000',
                    textAlign: 'center',
                    multiLine: false,
                    x: 75,
                    y: 32
                },
                subHeadline: {
                    text: "9 full-sizes yours for $49.50 with any $35 purchase",
                    fontSize: 14,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'center',
                    multiLine: false,
                    x: 75,
                    y: 55
                },
                cta: {
                    text: "Shop Now",
                    fontSize: 14,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'center',
                    x: 75,
                    y: 71
                }
            },
            23: {
                id: 23,
                enabled: true,
                template: 'C',
                width: 1280,
                height: 50,
                bgColor: '#ffffff',
                allTextColor: '#000000',
                bgImage: {
                    width: 201,
                    height: 'auto',
                    scale: 1,
                    rotate: 70,
                    align: 'center',
                    x: 49,
                    y: 115,
                    src: templateCbgImage
                },
                logo: {
                    width: 128,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 35.8,
                    y: 52,
                    src: templateCLogoBlack
                },
                image_1: {
                    width: 57,
                    height: 'auto',
                    scale: 1.57,
                    rotate: 0,
                    align: 'center',
                    x: 50,
                    y: 96,
                    src: templateCImage
                },
                image_2: null,
                headline: null,
                subHeadline: null,
                cta: {
                    text: "Shop Now",
                    fontSize: 14,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'left',
                    x: 59.7,
                    y: 51
                }
            },
            24: {
                id: 24,
                enabled: true,
                template: 'C',
                width: 954,
                height: 118,
                bgColor: '#ffffff',
                allTextColor: '#000000',
                bgImage: {
                    width: 742,
                    height: 'auto',
                    scale: 1,
                    rotate: 48,
                    align: 'center',
                    x: 11.5,
                    y: 142,
                    src: templateCbgImage
                },
                logo: {
                    width: 184,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 12.5,
                    y: 52,
                    src: templateCLogoBlack
                },
                image_1: {
                    width: 220,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 35.2,
                    y: 86,
                    src: templateCImage
                },
                image_2: null,
                headline: {
                    text: "Clinique's Best & Brightest",
                    fontSize: 35,
                    fontFamily: 'Macys Sans Bold',
                    fontColor: '#000000',
                    textAlign: 'center',
                    multiLine: false,
                    x: 73,
                    y: 32
                },
                subHeadline: {
                    text: "9 full-sizes yours for $49.50 with any $35 purchase",
                    fontSize: 14,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'center',
                    multiLine: false,
                    x: 73,
                    y: 58
                },
                cta: {
                    text: "Shop Now",
                    fontSize: 14,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'center',
                    x: 73,
                    y: 76
                }
            },
            25: {
                id: 25,
                enabled: true,
                template: 'C',
                width: 335,
                height: 94,
                bgColor: '#ffffff',
                allTextColor: '#000000',
                bgImage: {
                    width: 293,
                    height: 'auto',
                    scale: 1,
                    rotate: 48,
                    align: 'center',
                    x: 17.5,
                    y: 120,
                    src: templateCbgImage
                },
                logo: {
                    width: 71,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 14,
                    y: 49,
                    src: templateCLogoBlack
                },
                image_1: {
                    width: 94,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 39,
                    y: 62,
                    src: templateCImage
                },
                image_2: null,
                headline: null,
                subHeadline: {
                    text: "Yours for $49.50 with\r\nqualifying purchase",
                    fontSize: 14,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'center',
                    multiLine: true,
                    x: 76,
                    y: 35
                },
                cta: {
                    text: "Shop Now",
                    fontSize: 14,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'center',
                    x: 76,
                    y: 75
                }
            },
            26: {
                id: 26,
                enabled: true,
                template: 'C',
                width: 688,
                height: 536,
                bgColor: '#ffffff',
                allTextColor: '#000000',
                bgImage: {
                    width: 882,
                    height: 'auto',
                    scale: 1,
                    rotate: 9,
                    align: 'center',
                    x: 51.5,
                    y: 119,
                    src: templateCbgImage
                },
                logo: {
                    width: 244,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'left bottom',
                    x: 26,
                    y: 16.5,
                    src: templateCLogoBlack
                },
                image_1: {
                    width: 290,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 55,
                    y: 72,
                    src: templateCImage
                },
                image_2: null,
                headline: {
                    text: "Best & Brightest",
                    fontSize: 56,
                    fontFamily: 'Macys Sans Bold',
                    fontColor: '#000000',
                    textAlign: 'left',
                    multiLine: false,
                    x: 8,
                    y: 26
                },
                subHeadline: {
                    text: "yours for $49.50 with any $35 purchase",
                    fontSize: 22,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'left',
                    multiLine: false,
                    x: 8,
                    y: 36.5
                },
                cta: {
                    text: "Shop Now",
                    fontSize: 22,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'left',
                    x: 8,
                    y: 44
                }
            },
            27: {
                id: 27,
                enabled: true,
                template: 'C',
                width: 1280,
                height: 240,
                bgColor: '#ffffff',
                allTextColor: '#000000',
                bgImage: {
                    width: 881,
                    height: 'auto',
                    scale: 1,
                    rotate: 29,
                    align: 'center',
                    x: 25.5,
                    y: 149,
                    src: templateCbgImage
                },
                logo: {
                    width: 216,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 15.5,
                    y: 52,
                    src: templateCLogoBlack
                },
                image_1: {
                    width: 289,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 40.8,
                    y: 60,
                    src: templateCImage
                },
                image_2: null,
                headline: {
                    text: "Clinique Best & Brightest",
                    fontSize: 35,
                    fontFamily: 'Macys Sans Bold',
                    fontColor: '#000000',
                    textAlign: 'center',
                    multiLine: false,
                    x: 72.6,
                    y: 39
                },
                subHeadline: {
                    text: "yours for $49.50 with any $35 purchase",
                    fontSize: 18,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'center',
                    multiLine: false,
                    x: 72.4,
                    y: 55
                },
                cta: {
                    text: "Shop Now",
                    fontSize: 18,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'center',
                    x: 72.4,
                    y: 71
                }
            },
            28: {
                id: 28,
                enabled: true,
                template: 'C',
                width: 559,
                height: 133,
                bgColor: '#ffffff',
                allTextColor: '#000000',
                bgImage: {
                    width: 401,
                    height: 'auto',
                    scale: 1,
                    rotate: 52,
                    align: 'center',
                    x: 20.5,
                    y: 112,
                    src: templateCbgImage
                },
                logo: {
                    width: 124,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 14.5,
                    y: 51,
                    src: templateCLogoBlack
                },
                image_1: {
                    width: 128,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 39,
                    y: 62,
                    src: templateCImage
                },
                image_2: null,
                headline: {
                    text: "Clinique Best & Brightest",
                    fontSize: 20,
                    fontFamily: 'Macys Sans Bold',
                    fontColor: '#000000',
                    textAlign: 'center',
                    multiLine: false,
                    x: 75,
                    y: 31
                },
                subHeadline: {
                    text: "yours for $49.50 with any $35 purchase",
                    fontSize: 14,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'center',
                    multiLine: false,
                    x: 75,
                    y: 52
                },
                cta: {
                    text: "Shop Now",
                    fontSize: 18,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'center',
                    x: 75,
                    y: 74
                }
            },
            29: {
                id: 29,
                enabled: true,
                template: 'C',
                width: 1280,
                height: 80,
                bgColor: '#000000',
                bgImage: null,
                allTextColor: '#000000',
                logo: {
                    width: 131,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 49.4,
                    y: 52,
                    src: templateCLogoWhite
                },
                image_1: null,
                image_2: null,
                headline: null,
                subHeadline: null,
                cta: null,
            },
            30: {
                id: 30,
                enabled: true,
                template: 'C',
                width: 559,
                height: 80,
                bgColor: '#000000',
                bgImage: null,
                allTextColor: '#000000',
                logo: {
                    width: 124,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 50,
                    y: 51,
                    src: templateCLogoWhite
                },
                image_1: null,
                image_2: null,
                headline: null,
                subHeadline: null,
                cta: null,
            },
            31: {
                id: 31,
                enabled: true,
                template: 'D',
                width: 300,
                height: 250,
                bgColor: '#f0dddd',
                bgImage: null,
                allTextColor: '#000000',
                logo: {
                    width: 113,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'bottom left',
                    x: 23,
                    y: 21,
                    src: templateDLogoBlack
                },
                image_1: {
                    width: 130,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 71,
                    y: 50,
                    src: templateDImageOne
                },
                image_2: null,
                headline: {
                    text: "Free 7-pc gift",
                    fontSize: 18,
                    fontFamily: 'the new collection',
                    fontColor: '#000000',
                    textAlign: 'left',
                    multiLine: false,
                    x: 4,
                    y: 41
                },
                subHeadline: null,
                cta: {
                    text: "Shop Now",
                    fontSize: 14,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'left',
                    x: 4,
                    y: 52
                }
            },
            32: {
                id: 32,
                enabled: true,
                template: 'D',
                width: 1280,
                height: 160,
                bgColor: '#f0dddd',
                bgImage: null,
                allTextColor: '#000000',
                logo: {
                    width: 152,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 10.7,
                    y: 51,
                    src: templateDLogoBlack
                },
                image_1: {
                    width: 112,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 29.6,
                    y: 52,
                    src: templateDImageOne
                },
                image_2: {
                    width: 114,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 81,
                    y: 53,
                    src: templateDImageTwo
                },
                headline: {
                    text: "Free 7-pc gift",
                    fontSize: 35,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'left',
                    multiLine: false,
                    x: 43,
                    y: 36
                },
                subHeadline: {
                    text: "Choose yours with any $39.50 Inc purchase,\r\nover $140 value. While supplies last.",
                    fontSize: 18,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'left',
                    multiLine: true,
                    x: 43,
                    y: 64
                },
                cta: {
                    text: "Shop Now",
                    fontSize: 18,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'right',
                    x: 96.3,
                    y: 48
                }
            },
            33: {
                id: 33,
                enabled: true,
                template: 'D',
                width: 1280,
                height: 50,
                bgColor: '#f0dddd',
                bgImage: null,
                allTextColor: '#000000',
                logo: {
                    width: 67,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 42.6,
                    y: 52,
                    src: templateDLogoBlack
                },
                image_1: {
                    width: 35,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 49.5,
                    y: 51,
                    src: templateDImageOne
                },
                image_2: null,
                headline: null,
                subHeadline: null,
                cta: {
                    text: "Shop Now",
                    fontSize: 14,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'left',
                    x: 53.5,
                    y: 51
                }
            },
            34: {
                id: 34,
                enabled: true,
                template: 'D',
                width: 954,
                height: 118,
                bgColor: '#f0dddd',
                bgImage: null,
                allTextColor: '#000000',
                logo: {
                    width: 120,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 12.5,
                    y: 51,
                    src: templateDLogoBlack
                },
                image_1: {
                    width: 81,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 28.3,
                    y: 52,
                    src: templateDImageOne
                },
                image_2: {
                    width: 81,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 77.3,
                    y: 52,
                    src: templateDImageTwo
                },
                headline: {
                    text: "Free 7-pc gift",
                    fontSize: 25,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'left',
                    multiLine: false,
                    x: 38.7,
                    y: 33
                },
                subHeadline: {
                    text: "Choose yours with any $39.50 Inc purchase,\r\nover $140 value, While supplies last.",
                    fontSize: 14,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'left',
                    multiLine: true,
                    x: 38.7,
                    y: 62
                },
                cta: {
                    text: "Shop Now",
                    fontSize: 14,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'right',
                    x: 91.5,
                    y: 48
                }
            },
            35: {
                id: 35,
                enabled: true,
                template: 'D',
                width: 335,
                height: 94,
                bgColor: '#f0dddd',
                bgImage: null,
                allTextColor: '#000000',
                logo: {
                    width: 67,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'left bottom',
                    x: 15,
                    y: 32,
                    src: templateDLogoBlack
                },
                image_1: {
                    width: 72,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 80,
                    y: 51,
                    src: templateDImageOne
                },
                image_2: null,
                headline: {
                    text: "Free gift with qualifying purchase",
                    fontSize: 12,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'left',
                    multiLine: false,
                    x: 5,
                    y: 61
                },
                subHeadline: null,
                cta: {
                    text: "Shop Now",
                    fontSize: 12,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'left',
                    x: 5,
                    y: 79
                }
            },
            36: {
                id: 36,
                enabled: true,
                template: 'D',
                width: 688,
                height: 536,
                bgColor: '#f0dddd',
                bgImage: null,
                allTextColor: '#000000',
                logo: {
                    width: 162,
                    height: 'auto',
                    scale: 1.25,
                    rotate: 0,
                    align: 'bottom left',
                    x: 18,
                    y: 19,
                    src: templateDLogoBlack
                },
                image_1: {
                    width: 326,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 69,
                    y: 48,
                    src: templateDImageOne
                },
                image_2: null,
                headline: {
                    text: "Free 7-pc gift",
                    fontSize: 36,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'left',
                    multiLine: false,
                    x: 5.8,
                    y: 32
                },
                subHeadline: {
                    text: "*With qualifying purchase",
                    fontSize: 22,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'left',
                    multiLine: false,
                    x: 5.8,
                    y: 39.5
                },
                cta: {
                    text: "Shop Now",
                    fontSize: 25,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'left',
                    x: 5.8,
                    y: 48
                }
            },
            37: {
                id: 37,
                enabled: true,
                template: 'D',
                width: 1280,
                height: 240,
                bgColor: '#f0dddd',
                bgImage: null,
                allTextColor: '#000000',
                logo: {
                    width: 211,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 15.8,
                    y: 52,
                    src: templateDLogoBlack
                },
                image_1: {
                    width: 131,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 38,
                    y: 52,
                    src: templateDImageOne
                },
                image_2: {
                    width: 131,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 88,
                    y: 52,
                    src: templateDImageTwo
                },
                headline: {
                    text: "Free 7-pc gift",
                    fontSize: 35,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'left',
                    multiLine: false,
                    x: 50.4,
                    y: 34
                },
                subHeadline: {
                    text: "Choose yours with any $39.50 Inc purchase,\r\nover $140 value. While supplies last.",
                    fontSize: 18,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'left',
                    multiLine: true,
                    x: 50.4,
                    y: 54
                },
                cta: {
                    text: "Shop Now",
                    fontSize: 18,
                    fontFamily: 'over $140 value. While supplies last',
                    fontColor: '#000000',
                    textAlign: 'left',
                    x: 50.4,
                    y: 71
                }
            },
            38: {
                id: 38,
                enabled: true,
                template: 'D',
                width: 559,
                height: 133,
                bgColor: '#f0dddd',
                bgImage: null,
                allTextColor: '#000000',
                logo: {
                    width: 124,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 17.5,
                    y: 51,
                    src: templateDLogoBlack
                },
                image_1: {
                    width: 67,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 40,
                    y: 48,
                    src: templateDImageOne
                },
                image_2: {
                    width: 67,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 88,
                    y: 48,
                    src: templateDImageTwo
                },
                headline: {
                    text: "Free 7-pc gift",
                    fontSize: 20,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'left',
                    multiLine: false,
                    x: 50,
                    y: 31
                },
                subHeadline: {
                    text: "*With qualifying purchase",
                    fontSize: 14,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'left',
                    multiLine: false,
                    x: 50,
                    y: 52
                },
                cta: {
                    text: "Shop Now",
                    fontSize: 14,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'left',
                    x: 50,
                    y: 69
                }
            },
            39: {
                id: 39,
                enabled: true,
                template: 'D',
                width: 1280,
                height: 80,
                bgColor: '#000000',
                bgImage: null,
                allTextColor: '#000000',
                logo: {
                    width: 95,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 50,
                    y: 52,
                    src: templateDLogoWhite
                },
                image_1: null,
                image_2: null,
                headline: null,
                subHeadline: null,
                cta: null,
            },
            40: {
                id: 40,
                enabled: true,
                template: 'D',
                width: 559,
                height: 80,
                bgColor: '#000000',
                bgImage: null,
                allTextColor: '#000000',
                logo: {
                    width: 100,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 50,
                    y: 51,
                    src: templateDLogoWhite
                },
                image_1: null,
                image_2: null,
                headline: null,
                subHeadline: null,
                cta: null,
            },
            41: {
                id: 41,
                enabled: true,
                template: 'E',
                width: 300,
                height: 250,
                bgColor: '#d3cfcd',
                bgImage: null,
                allTextColor: '#000000',
                logo: {
                    width: 113,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'left bottom',
                    x: 23,
                    y: 22,
                    src: templateELogoBlack
                },
                image_1: {
                    width: 136,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 75,
                    y: 47,
                    src: templateEImageOne
                },
                image_2: null,
                headline: {
                    text: "The New Fragrance",
                    fontSize: 18,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'left',
                    multiLine: false,
                    x: 4,
                    y: 41
                },
                subHeadline: null,
                cta: {
                    text: "Shop Now",
                    fontSize: 18,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'left',
                    x: 4,
                    y: 52
                },
                promo: {
                    textWrap: {
                        text: ["Enjoy ", "% Off", ". Use Code: "],
                        css: {
                            fontSize: 14,
                            fontFamily: 'Macys Sans Regular',
                            color: '#FFFFFF',
                            textAlign: 'left',
                            marginLeft: 15
                        }
                    },
                    discount: {
                        text: "15",
                        css: {
                            fontSize: 14,
                            fontFamily: 'Macys Sans Bold',
                            color: '#FFFFFF'
                        }
                    },
                    code: {
                        text: "VIP",
                        css: {
                            fontSize: 14,
                            fontFamily: 'Macys Sans Bold',
                            color: '#FFFFFF'
                        }
                    },
                    background: {
                        css: {
                            alignItems: 'center',
                            top: 'auto',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            width: 'auto',
                            height: 29,
                            backgroundColor: '#85817F'
                        }
                    }
                }
            },
            42: {
                id: 42,
                enabled: true,
                template: 'E',
                width: 1280,
                height: 160,
                bgColor: '#d3cfcd',
                bgImage: null,
                allTextColor: '#000000',
                logo: {
                    width: 152,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 10.7,
                    y: 51,
                    src: templateELogoBlack
                },
                image_1: {
                    width: 112,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 29.6,
                    y: 52,
                    src: templateEImageOne
                },
                image_2: null,
                headline: {
                    text: "The New Fragrance",
                    fontSize: 35,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'left',
                    multiLine: false,
                    x: 44.3,
                    y: 33
                },
                subHeadline: {
                    text: "Now Available in a large size.",
                    fontSize: 18,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'left',
                    multiLine: false,
                    x: 44.3,
                    y: 57
                },
                cta: {
                    text: "Shop Now",
                    fontSize: 18,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'left',
                    x: 44.3,
                    y: 74
                },
                promo: {
                    textWrap: {
                        text: ["Enjoy ", "% Off\r\n", "Use Code:\r\n"],
                        css: {
                            fontSize: 25,
                            fontFamily: 'Macys Sans Regular',
                            color: '#FFFFFF',
                            textAlign: 'center',
                        }
                    },
                    discount: {
                        text: "15",
                        css: {
                            fontSize: 25,
                            fontFamily: 'Macys Sans Bold',
                            color: '#FFFFFF'
                        }
                    },
                    code: {
                        text: "VIP",
                        css: {
                            fontSize: 25,
                            fontFamily: 'Macys Sans Bold',
                            color: '#FFFFFF'
                        }
                    },
                    background: {
                        css: {
                            alignItems: 'center',
                            top: 0,
                            bottom: 0,
                            left: 'auto',
                            right: 0,
                            width: 204,
                            height: 'auto',
                            justifyContent: 'center',
                            lineHeight: 1.5,
                            backgroundColor: '#85817F'
                        }
                    }
                }
            },
            43: {
                id: 43,
                enabled: true,
                template: 'E',
                width: 1280,
                height: 50,
                bgColor: '#d3cfcd',
                bgImage: null,
                allTextColor: '#000000',
                logo: {
                    width: 67,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 42.6,
                    y: 52,
                    src: templateELogoBlack
                },
                image_1: {
                    width: 40,
                    height: 'auto',
                    scale: 0.8,
                    rotate: 0,
                    align: 'center',
                    x: 49.5,
                    y: 52,
                    src: templateEImageOne
                },
                image_2: null,
                headline: null,
                subHeadline: null,
                cta: {
                    text: "Shop Now",
                    fontSize: 14,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'left',
                    x: 53.5,
                    y: 51
                }
            },
            44: {
                id: 44,
                enabled: true,
                template: 'E',
                width: 954,
                height: 118,
                bgColor: '#d3cfcd',
                bgImage: null,
                allTextColor: '#000000',
                logo: {
                    width: 120,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 12.5,
                    y: 51,
                    src: templateELogoBlack
                },
                image_1: {
                    width: 75,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 31,
                    y: 52,
                    src: templateEImageOne
                },
                image_2: null,
                headline: {
                    text: "The New Fragrance",
                    fontSize: 25,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'left',
                    multiLine: false,
                    x: 44,
                    y: 31
                },
                subHeadline: {
                    text: "Now Available in a large size.",
                    fontSize: 14,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'left',
                    multiLine: false,
                    x: 44,
                    y: 55
                },
                cta: {
                    text: "Shop Now",
                    fontSize: 14,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'left',
                    x: 44,
                    y: 81
                },
                promo: {
                    textWrap: {
                        text: ["Enjoy ", "% Off\r\n", "Use Code:\r\n"],
                        css: {
                            fontSize: 25,
                            fontFamily: 'Macys Sans Regular',
                            color: '#FFFFFF',
                            textAlign: 'center',
                        }
                    },
                    discount: {
                        text: "15",
                        css: {
                            fontSize: 25,
                            fontFamily: 'Macys Sans Bold',
                            color: '#FFFFFF'
                        }
                    },
                    code: {
                        text: "VIP",
                        css: {
                            fontSize: 25,
                            fontFamily: 'Macys Sans Bold',
                            color: '#FFFFFF'
                        }
                    },
                    background: {
                        css: {
                            alignItems: 'center',
                            top: 0,
                            bottom: 0,
                            left: 'auto',
                            right: 0,
                            width: 199,
                            height: 'auto',
                            justifyContent: 'center',
                            lineHeight: 1.25,
                            backgroundColor: '#85817F'
                        }
                    }
                }
            },
            45: {
                id: 45,
                enabled: true,
                template: 'E',
                width: 335,
                height: 94,
                bgColor: '#d3cfcd',
                bgImage: null,
                allTextColor: '#000000',
                logo: {
                    width: 67,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'left bottom',
                    x: 15,
                    y: 32,
                    src: templateDLogoBlack
                },
                image_1: {
                    width: 50,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 49,
                    y: 51,
                    src: templateEImageOne
                },
                image_2: null,
                headline: {
                    text: "The New Fragrance",
                    fontSize: 12,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'left',
                    multiLine: false,
                    x: 4.7,
                    y: 61
                },
                subHeadline: null,
                cta: {
                    text: "Shop Now",
                    fontSize: 12,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'left',
                    x: 4.7,
                    y: 79
                },
                promo: {
                    textWrap: {
                        text: ["Enjoy ", "% Off\r\n", "Use Code:\r\n"],
                        css: {
                            fontSize: 18,
                            fontFamily: 'Macys Sans Regular',
                            color: '#FFFFFF',
                            textAlign: 'center',
                        }
                    },
                    discount: {
                        text: "15",
                        css: {
                            fontSize: 18,
                            fontFamily: 'Macys Sans Bold',
                            color: '#FFFFFF'
                        }
                    },
                    code: {
                        text: "VIP",
                        css: {
                            fontSize: 18,
                            fontFamily: 'Macys Sans Bold',
                            color: '#FFFFFF'
                        }
                    },
                    background: {
                        css: {
                            alignItems: 'center',
                            top: 0,
                            bottom: 0,
                            left: 'auto',
                            right: 0,
                            width: 132,
                            height: 'auto',
                            justifyContent: 'center',
                            lineHeight: 1.2,
                            backgroundColor: '#85817F'
                        }
                    }
                }
            },
            46: {
                id: 46,
                enabled: true,
                template: 'E',
                width: 688,
                height: 536,
                bgColor: '#d3cfcd',
                bgImage: null,
                allTextColor: '#000000',
                logo: {
                    width: 135,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'left bottom',
                    x: 17.1,
                    y: 17.5,
                    src: templateELogoBlack
                },
                image_1: {
                    width: 310,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 71,
                    y: 41,
                    src: templateEImageOne
                },
                image_2: null,
                headline: {
                    text: "The New Fragrance",
                    fontSize: 36,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'left',
                    multiLine: false,
                    x: 7,
                    y: 32
                },
                subHeadline: {
                    text: "Now available in a large size.",
                    fontSize: 22,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'left',
                    multiLine: false,
                    x: 7,
                    y: 39.5
                },
                cta: {
                    text: "Shop Now",
                    fontSize: 25,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'left',
                    x: 7,
                    y: 48
                },
                promo: {
                    textWrap: {
                        text: ["Enjoy ", "% Off", ". Use Code: "],
                        css: {
                            fontSize: 24,
                            fontFamily: 'Macys Sans Regular',
                            color: '#FFFFFF',
                            marginLeft: 50,
                            marginTop: 20
                        }
                    },
                    discount: {
                        text: "15",
                        css: {
                            fontSize: 24,
                            fontFamily: 'Macys Sans Bold',
                            color: '#FFFFFF'
                        }
                    },
                    code: {
                        text: "VIP",
                        css: {
                            fontSize: 24,
                            fontFamily: 'Macys Sans Bold',
                            color: '#FFFFFF'
                        }
                    },
                    background: {
                        css: {
                            alignItems: 'flex-start',
                            justifyContent: 'flex-start',
                            top: 'auto',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            width: 'auto',
                            height: 113,
                            backgroundColor: '#85817F'
                        }
                    }
                }
            },
            47: {
                id: 47,
                enabled: true,
                template: 'E',
                width: 1280,
                height: 240,
                bgColor: '#d3cfcc',
                bgImage: null,
                allTextColor: '#000000',
                logo: {
                    width: 211,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 15.8,
                    y: 52,
                    src: templateELogoBlack
                },
                image_1: {
                    width: 121,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 35,
                    y: 52,
                    src: templateEImageOne
                },
                image_2: null,
                headline: {
                    text: "The New Fragrance",
                    fontSize: 35,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'left',
                    multiLine: false,
                    x: 47,
                    y: 34
                },
                subHeadline: {
                    text: "Now Available in a large size.",
                    fontSize: 18,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'left',
                    multiLine: false,
                    x: 47,
                    y: 50
                },
                cta: {
                    text: "Shop Now",
                    fontSize: 18,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'left',
                    x: 47,
                    y: 67
                },
                promo: {
                    textWrap: {
                        text: ["Enjoy ", "% Off\r\n", "Use Code:\r\n"],
                        css: {
                            fontSize: 35,
                            fontFamily: 'Macys Sans Regular',
                            color: '#FFFFFF',
                            textAlign: 'center',
                            lineHeight: 1.5
                        }
                    },
                    discount: {
                        text: "15",
                        css: {
                            fontSize: 35,
                            fontFamily: 'Macys Sans Bold',
                            color: '#FFFFFF'
                        }
                    },
                    code: {
                        text: "VIP",
                        css: {
                            fontSize: 35,
                            fontFamily: 'Macys Sans Bold',
                            color: '#FFFFFF'
                        }
                    },
                    background: {
                        css: {
                            alignItems: 'center',
                            justifyContent: 'center',
                            top: 0,
                            bottom: 0,
                            left: 'auto',
                            right: 0,
                            width: 286,
                            height: 'auto',
                            backgroundColor: '#85817F'
                        }
                    }
                }
            },
            48: {
                id: 48,
                enabled: true,
                template: 'E',
                width: 559,
                height: 133,
                bgColor: '#d3cfcd',
                bgImage: null,
                allTextColor: '#000000',
                logo: {
                    width: 124,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 17.5,
                    y: 45,
                    src: templateELogoBlack
                },
                image_1: {
                    width: 67,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 44.5,
                    y: 41,
                    src: templateEImageOne
                },
                image_2: null,
                headline: {
                    text: "The New Fragrance",
                    fontSize: 20,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'left',
                    multiLine: false,
                    x: 60,
                    y: 25
                },
                subHeadline: {
                    text: "Now available in a large size.",
                    fontSize: 14,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'left',
                    multiLine: false,
                    x: 60,
                    y: 44
                },
                cta: {
                    text: "Shop Now",
                    fontSize: 14,
                    fontFamily: 'Macys Sans Regular',
                    fontColor: '#000000',
                    textAlign: 'left',
                    x: 60,
                    y: 61
                },
                promo: {
                    textWrap: {
                        text: ["Enjoy ", "% Off", ". Use Code: "],
                        css: {
                            fontSize: 18,
                            fontFamily: 'Macys Sans Regular',
                            color: '#FFFFFF',
                            textAlign: 'left',
                            marginLeft: 35
                        }
                    },
                    discount: {
                        text: "15",
                        css: {
                            fontSize: 18,
                            fontFamily: 'Macys Sans Bold',
                            color: '#FFFFFF'
                        }

                    },
                    code: {
                        text: "VIP",
                        css: {
                            fontSize: 18,
                            fontFamily: 'Macys Sans Bold',
                            color: '#FFFFFF'
                        }
                    },
                    background: {
                        css: {
                            alignItems: 'center',
                            top: 'auto',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            width: 'auto',
                            height: 29,
                            backgroundColor: '#85817F'
                        }
                    }
                }
            },
            49: {
                id: 49,
                enabled: true,
                template: 'E',
                width: 1280,
                height: 80,
                bgColor: '#000000',
                bgImage: null,
                logo: {
                    width: 95,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 50,
                    y: 52,
                    src: templateELogoWhite
                },
                image_1: null,
                image_2: null,
                headline: null,
                subHeadline: null,
                cta: null,
            },
            50: {
                id: 50,
                enabled: true,
                template: 'E',
                width: 559,
                height: 80,
                bgColor: '#000000',
                bgImage: null,
                logo: {
                    width: 100,
                    height: 'auto',
                    scale: 1,
                    rotate: 0,
                    align: 'center',
                    x: 50,
                    y: 51,
                    src: templateELogoWhite
                },
                image_1: null,
                image_2: null,
                headline: null,
                subHeadline: null,
                cta: null,
            }
        },
        allIds: [
            1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
            11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
            21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
            31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
            41, 42, 43, 44, 45, 46, 47, 48, 49, 50
        ]
    }
}


const xPositionEvery = (image, banner, initialBanner, action) => {
    let centerPoint;
    let newXPosInPixels;
    let XPosInDecimal;

    let pixelsToRight;

    if (Math.sign(action.payload) < 0) {
        centerPoint = initialBanner.width * (initialBanner[image].x * 0.01);
        newXPosInPixels = centerPoint + (centerPoint * action.payload);
        XPosInDecimal = newXPosInPixels / banner.width;
        banner[image].x = XPosInDecimal * 100;
    } else {
        centerPoint = initialBanner.width * (initialBanner[image].x * 0.01);
        pixelsToRight = banner.width - centerPoint;
        newXPosInPixels = centerPoint + (pixelsToRight * action.payload);
        XPosInDecimal = newXPosInPixels / banner.width;
        banner[image].x = XPosInDecimal * 100;
    }
}

const yPositionEvery = (image, banner, initialBanner, action) => {
    let centerPoint;
    let newYPosInPixels;
    let YPosInDecimal;

    let pixelsToBottom;

    if (Math.sign(action.payload) < 0) {
        centerPoint = initialBanner.height * (initialBanner[image].y * 0.01);
        newYPosInPixels = centerPoint + (centerPoint * action.payload);
        YPosInDecimal = newYPosInPixels / banner.height;
        banner[image].y = YPosInDecimal * 100;
    } else {
        centerPoint = initialBanner.height * (initialBanner[image].y * 0.01);
        pixelsToBottom = banner.height - centerPoint;
        newYPosInPixels = centerPoint + (pixelsToBottom * action.payload);
        YPosInDecimal = newYPosInPixels / banner.height;
        banner[image].y = YPosInDecimal * 100;
    }
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SELECT_BANNER:
            return produce(state, draft => {
                draft.selectedBanner = action.payload
            });
        case SELECT_TEMPLATE:
            return produce(state, draft => {
                draft.selectedTemplate = action.payload
            });
        case TOGGLE_BANNER:
            return produce(state, draft => {
                draft.banners["byId"][action.payload.id].enabled = !action.payload.value;
            });
        case BG_IMAGE_SRC:
            return produce(state, draft => {
                draft.banners["byId"][action.payload.id].bgImage.src = action.payload.value;
            });
        case BG_IMAGE_SIZE:
            return produce(state, draft => {
                draft.banners["byId"][action.payload.id].bgImage.scale = action.payload.value;
            });
        case BG_IMAGE_ROTATE:
            return produce(state, draft => {
                draft.banners["byId"][action.payload.id].bgImage.rotate = action.payload.value;
            });
        case BG_IMAGE_X_POSITION:
            return produce(state, draft => {
                draft.banners["byId"][action.payload.id].bgImage.x = action.payload.value;
            });
        case BG_IMAGE_Y_POSITION:
            return produce(state, draft => {
                draft.banners["byId"][action.payload.id].bgImage.y = action.payload.value;
            });
        case BG_IMAGE_RESET:
            return produce(state, draft => {
                draft.banners["byId"][action.payload.id].bgImage.scale = INITIAL_STATE.banners["byId"][action.payload.id].bgImage.scale;
                draft.banners["byId"][action.payload.id].bgImage.rotate = INITIAL_STATE.banners["byId"][action.payload.id].bgImage.rotate;
                draft.banners["byId"][action.payload.id].bgImage.x = INITIAL_STATE.banners["byId"][action.payload.id].bgImage.x
                draft.banners["byId"][action.payload.id].bgImage.y = INITIAL_STATE.banners["byId"][action.payload.id].bgImage.y
            });
        case LOGO_SRC:
            return produce(state, draft => {
                draft.banners["byId"][action.payload.id].logo.src = action.payload.value;
            });
        case LOGO_SIZE:
            return produce(state, draft => {
                draft.banners["byId"][action.payload.id].logo.scale = action.payload.value;
            });
        case LOGO_X_POSITION:
            return produce(state, draft => {
                draft.banners["byId"][action.payload.id].logo.x = action.payload.value;
            });
        case LOGO_Y_POSITION:
            return produce(state, draft => {
                draft.banners["byId"][action.payload.id].logo.y = action.payload.value;
            });
        case LOGO_RESET:
            return produce(state, draft => {
                draft.banners["byId"][action.payload.id].logo.scale = INITIAL_STATE.banners["byId"][action.payload.id].logo.scale;
                draft.banners["byId"][action.payload.id].logo.x = INITIAL_STATE.banners["byId"][action.payload.id].logo.x
                draft.banners["byId"][action.payload.id].logo.y = INITIAL_STATE.banners["byId"][action.payload.id].logo.y
            });
        case IMAGE_1_SRC:
            return produce(state, draft => {
                draft.banners["byId"][action.payload.id].image_1.src = action.payload.value;
            });
        case IMAGE_1_SIZE:
            return produce(state, draft => {
                draft.banners["byId"][action.payload.id].image_1.scale = action.payload.value;
            });
        case IMAGE_1_X_POSITION:
            return produce(state, draft => {
                draft.banners["byId"][action.payload.id].image_1.x = action.payload.value;
            });
        case IMAGE_1_Y_POSITION:
            return produce(state, draft => {
                draft.banners["byId"][action.payload.id].image_1.y = action.payload.value;
            });
        case IMAGE_1_RESET:
            return produce(state, draft => {
                draft.banners["byId"][action.payload.id].image_1.scale = INITIAL_STATE.banners["byId"][action.payload.id].image_1.scale;
                draft.banners["byId"][action.payload.id].image_1.x = INITIAL_STATE.banners["byId"][action.payload.id].image_1.x
                draft.banners["byId"][action.payload.id].image_1.y = INITIAL_STATE.banners["byId"][action.payload.id].image_1.y
            });
        case IMAGE_2_SRC:
            return produce(state, draft => {
                draft.banners["byId"][action.payload.id].image_2.src = action.payload.value;
            });
        case IMAGE_2_SIZE:
            return produce(state, draft => {
                draft.banners["byId"][action.payload.id].image_2.scale = action.payload.value;
            });
        case IMAGE_2_X_POSITION:
            return produce(state, draft => {
                draft.banners["byId"][action.payload.id].image_2.x = action.payload.value;
            });
        case IMAGE_2_Y_POSITION:
            return produce(state, draft => {
                draft.banners["byId"][action.payload.id].image_2.y = action.payload.value;
            });
        case IMAGE_2_RESET:
            return produce(state, draft => {
                draft.banners["byId"][action.payload.id].image_2.scale = INITIAL_STATE.banners["byId"][action.payload.id].image_2.scale;
                draft.banners["byId"][action.payload.id].image_2.x = INITIAL_STATE.banners["byId"][action.payload.id].image_2.x
                draft.banners["byId"][action.payload.id].image_2.y = INITIAL_STATE.banners["byId"][action.payload.id].image_2.y
            });
        case CTA_TEXT:
            return produce(state, draft => {
                draft.banners["byId"][action.payload.id].cta.text = action.payload.value;
            });
        case CTA_FONT_SIZE:
            return produce(state, draft => {
                draft.banners["byId"][action.payload.id].cta.fontSize = action.payload.value;
            });
        case CTA_FONT_FAMILY:
            return produce(state, draft => {
                draft.banners["byId"][action.payload.id].cta.fontFamily = action.payload.value;
            });
        case CTA_FONT_COLOR:
            return produce(state, draft => {
                draft.banners["byId"][action.payload.id].cta.fontColor = action.payload.value;
            });
        case CTA_X_POSITION:
            return produce(state, draft => {
                draft.banners["byId"][action.payload.id].cta.x = action.payload.value
            });
        case CTA_Y_POSITION:
            return produce(state, draft => {
                draft.banners["byId"][action.payload.id].cta.y = action.payload.value
            });
        case PROMO_DISCOUNT_TEXT:
            return produce(state, draft => {
                draft.banners["byId"][action.payload.id].promo.discount.text = action.payload.value
            });
        case PROMO_CODE_TEXT:
            return produce(state, draft => {
                draft.banners["byId"][action.payload.id].promo.code.text = action.payload.value
            });
        case HEADLINE_TEXT:
            return produce(state, draft => {
                draft.banners["byId"][action.payload.id].headline.text = action.payload.value
            });
        case HEADLINE_FONT_SIZE:
            return produce(state, draft => {
                draft.banners["byId"][action.payload.id].headline.fontSize = action.payload.value
            });
        case HEADLINE_FONT_FAMILY:
            return produce(state, draft => {
                draft.banners["byId"][action.payload.id].headline.fontFamily = action.payload.value
            });
        case HEADLINE_FONT_COLOR:
            return produce(state, draft => {
                draft.banners["byId"][action.payload.id].headline.fontColor = action.payload.value
            });
        case HEADLINE_X_POSITION:
            return produce(state, draft => {
                draft.banners["byId"][action.payload.id].headline.x = action.payload.value
            });
        case HEADLINE_Y_POSITION:
            return produce(state, draft => {
                draft.banners["byId"][action.payload.id].headline.y = action.payload.value
            });
        case SUB_HEADLINE_TEXT:
            return produce(state, draft => {
                draft.banners["byId"][action.payload.id].subHeadline.text = action.payload.value
            });
        case SUB_HEADLINE_FONT_SIZE:
            return produce(state, draft => {
                draft.banners["byId"][action.payload.id].subHeadline.fontSize = action.payload.value
            });
        case SUB_HEADLINE_FONT_FAMILY:
            return produce(state, draft => {
                draft.banners["byId"][action.payload.id].subHeadline.fontFamily = action.payload.value
            });
        case SUB_HEADLINE_FONT_COLOR:
            return produce(state, draft => {
                draft.banners["byId"][action.payload.id].subHeadline.fontColor = action.payload.value
            });
        case SUB_HEADLINE_X_POSITION:
            return produce(state, draft => {
                draft.banners["byId"][action.payload.id].subHeadline.x = action.payload.value
            });
        case SUB_HEADLINE_Y_POSITION:
            return produce(state, draft => {
                draft.banners["byId"][action.payload.id].subHeadline.y = action.payload.value
            });
        case BG_COLOR_EDIT:
            return produce(state, draft => {
                draft.banners["byId"][action.payload.id].bgColor = action.payload.value
            });
        case NAMING_CONVENTION:
            return produce(state, draft => {
                draft.templates["byId"][state.selectedTemplate].namingConvention = action.payload;
            });
        case PERSISTENT_NAMING_CONVENTION:
            return produce(state, draft => {
                draft.persistentNamingConvention = action.payload;
            })
        case LINKING_URL:
            return produce(state, draft => {
                draft.templates["byId"][state.selectedTemplate].linkingUrl = action.payload;
            });
        case ALL_TEXT_COLOR_INDIVIDUAL:
            return produce(state, draft => {
                draft.banners.byId[action.payload.id].AllTextColor = action.payload.value;
                state.banners.byId[action.payload.id].headline != null ? draft.banners.byId[state.selectedBanner].headline.fontColor = action.payload.value : null;
                state.banners.byId[action.payload.id].subHeadline != null ? draft.banners.byId[state.selectedBanner].subHeadline.fontColor = action.payload.value : null;
                state.banners.byId[action.payload.id].cta != null ? draft.banners.byId[state.selectedBanner].cta.fontColor = action.payload.value : null;
            });
        case ALL_TEXT_COLOR_EVERY:
            return produce(state, draft => {
                draft.templates["byId"][state.selectedTemplate].AllTextColor = action.payload;
                Object.values(draft.banners.byId).forEach(banner => {
                    if (banner.template === state.selectedTemplate) {
                        banner.AllTextColor = action.payload;
                        banner.headline != null ? banner.headline.fontColor = action.payload : null;
                        banner.subHeadline != null ? banner.subHeadline.fontColor = action.payload : null;
                        banner.cta != null ? banner.cta.fontColor = action.payload : null;
                    }
                });
            });
        case BG_IMAGE_SRC_EVERY:
            return produce(state, draft => {
                draft.templates["byId"][state.selectedTemplate].bgImageSrcAll = action.payload;
                Object.values(draft.banners.byId).forEach((banner) => {
                    if (banner.template === state.selectedTemplate && banner.bgImage != null) {
                        banner.bgImage.src = action.payload
                    }
                });
            });
        case BG_IMAGE_SIZE_EVERY:
            return produce(state, draft => {
                draft.templates["byId"][state.selectedTemplate].bgImageScaleAll = action.payload;
                Object.values(draft.banners.byId).forEach((banner) => {
                    if (banner.template === state.selectedTemplate && banner.bgImage != null) {
                        banner.bgImage.scale = action.payload
                    }
                });
            });
        case BG_IMAGE_ROTATE_EVERY:
            return produce(state, draft => {
                draft.templates["byId"][state.selectedTemplate].bgImageRotateAll = action.payload;
                Object.values(draft.banners.byId).forEach((banner) => {
                    if (banner.template === state.selectedTemplate && banner.bgImage != null) {
                        banner.bgImage.rotate = action.payload
                    }
                });
            });
        case BG_IMAGE_X_POSITION_EVERY:
            return produce(state, draft => {
                draft.templates["byId"][state.selectedTemplate].bgImageXPositionAll = action.payload;
                Object.values(draft.banners.byId).forEach((banner) => {
                    Object.values(INITIAL_STATE.banners.byId).forEach(initialBanner => {
                        if (
                            (banner.template === state.selectedTemplate && banner.bgImage != null) &&
                            (initialBanner.template === state.selectedTemplate && initialBanner.bgImage != null) &&
                            (banner.id === initialBanner.id)
                        ) {

                            xPositionEvery('bgImage', banner, initialBanner, action);
                        }
                    });
                });
            });
        case BG_IMAGE_Y_POSITION_EVERY:
            return produce(state, draft => {
                draft.templates["byId"][state.selectedTemplate].bgImageYPositionAll = action.payload;
                Object.values(draft.banners.byId).forEach((banner) => {
                    Object.values(INITIAL_STATE.banners.byId).forEach(initialBanner => {
                        if (
                            (banner.template === state.selectedTemplate && banner.bgImage != null) &&
                            (initialBanner.template === state.selectedTemplate && initialBanner.bgImage != null) &&
                            (banner.id === initialBanner.id)
                        ) {

                            yPositionEvery('bgImage', banner, initialBanner, action);
                        }
                    });
                });
            });
        case BG_IMAGE_RESET_EVERY:
            return produce(state, draft => {
                Object.values(draft.banners.byId).forEach(banner => {
                    Object.values(INITIAL_STATE.banners.byId).forEach(initialBanner => {
                        if (
                            (banner.template === state.selectedTemplate && banner.bgImage != null) &&
                            (initialBanner.template === state.selectedTemplate && initialBanner.bgImage != null) &&
                            (banner.id === initialBanner.id)
                        ) {
                            banner.bgImage.x = initialBanner.bgImage.x;
                            banner.bgImage.y = initialBanner.bgImage.y;
                            banner.bgImage.scale = initialBanner.bgImage.scale;
                            banner.bgImage.rotate = initialBanner.bgImage.rotate;
                        }
                    });
                });
                draft.templates["byId"][state.selectedTemplate].bgImageScaleAll = 1;
                draft.templates["byId"][state.selectedTemplate].bgImageXPositionAll = 0;
                draft.templates["byId"][state.selectedTemplate].bgImageYPositionAll = 0;
            });
        case LOGO_SRC_EVERY:
            return produce(state, draft => {
                draft.templates["byId"][state.selectedTemplate].logoSrcAll = action.payload;
                Object.values(draft.banners.byId).forEach((banner) => {
                    if (banner.template === state.selectedTemplate && banner.logo != null) {
                        banner.logo.src = action.payload
                    }
                });
            });
        case LOGO_SIZE_EVERY:
            return produce(state, draft => {
                draft.templates["byId"][state.selectedTemplate].logoScaleAll = action.payload;
                Object.values(draft.banners.byId).forEach((banner) => {
                    if (banner.template === state.selectedTemplate && banner.logo != null) {
                        banner.logo.scale = action.payload
                    }
                });
            });
        case LOGO_X_POSITION_EVERY:
            return produce(state, draft => {
                draft.templates["byId"][state.selectedTemplate].logoXPositionAll = action.payload;
                Object.values(draft.banners.byId).forEach((banner) => {
                    Object.values(INITIAL_STATE.banners.byId).forEach(initialBanner => {
                        if (
                            (banner.template === state.selectedTemplate && banner.logo != null) &&
                            (initialBanner.template === state.selectedTemplate && initialBanner.logo != null) &&
                            (banner.id === initialBanner.id)
                        ) {

                            xPositionEvery('logo', banner, initialBanner, action);
                        }
                    });
                });
            });
        case LOGO_Y_POSITION_EVERY:
            return produce(state, draft => {
                draft.templates["byId"][state.selectedTemplate].logoYPositionAll = action.payload;
                Object.values(draft.banners.byId).forEach((banner) => {
                    Object.values(INITIAL_STATE.banners.byId).forEach(initialBanner => {
                        if (
                            (banner.template === state.selectedTemplate && banner.logo != null) &&
                            (initialBanner.template === state.selectedTemplate && initialBanner.logo != null) &&
                            (banner.id === initialBanner.id)
                        ) {

                            yPositionEvery('logo', banner, initialBanner, action);
                        }
                    });
                });
            });
        case LOGO_RESET_EVERY:
            return produce(state, draft => {
                Object.values(draft.banners.byId).forEach(banner => {
                    Object.values(INITIAL_STATE.banners).forEach(initialBanner => {
                        if (
                            (banner.template === state.selectedTemplate) &&
                            (initialBanner.template === state.selectedTemplate) &&
                            (banner.id === initialBanner.id)
                        ) {
                            banner.logo.x = initialBanner.logo.x;
                            banner.logo.y = initialBanner.logo.y;
                            banner.logo.scale = initialBanner.logo.scale;
                            banner.logo.rotate = initialBanner.logo.rotate;
                        }
                    });
                });
                draft.templates["byId"][state.selectedTemplate].logoScaleAll = 1;
                draft.templates["byId"][state.selectedTemplate].logoXPositionAll = 0;
                draft.templates["byId"][state.selectedTemplate].logoYPositionAll = 0;
            });
        case IMAGE_1_SRC_EVERY:
            return produce(state, draft => {
                draft.templates["byId"][state.selectedTemplate].image1SrcAll = action.payload;
                Object.values(draft.banners.byId).forEach((banner) => {
                    if (banner.template === state.selectedTemplate && banner.image_1 != null) {
                        banner.image_1.src = action.payload
                    }
                });
            });
        case IMAGE_1_SIZE_EVERY:
            return produce(state, draft => {
                draft.templates["byId"][state.selectedTemplate].image1ScaleAll = action.payload;
                Object.values(draft.banners.byId).forEach((banner) => {
                    if (banner.template === state.selectedTemplate && banner.image_1 != null) {
                        banner.image_1.scale = action.payload
                    }
                });
            });
        case IMAGE_1_X_POSITION_EVERY:
            return produce(state, draft => {
                draft.templates["byId"][state.selectedTemplate].image1XPositionAll = action.payload;
                Object.values(draft.banners.byId).forEach((banner) => {
                    Object.values(INITIAL_STATE.banners.byId).forEach(initialBanner => {
                        if (
                            (banner.template === state.selectedTemplate && banner.image_1 != null) &&
                            (initialBanner.template === state.selectedTemplate && initialBanner.image_1 != null) &&
                            (banner.id === initialBanner.id)
                        ) {
                            xPositionEvery('image_1', banner, initialBanner, action);
                        }
                    });
                });
            });
        case IMAGE_1_Y_POSITION_EVERY:
            return produce(state, draft => {
                draft.templates["byId"][state.selectedTemplate].image1YPositionAll = action.payload;
                Object.values(draft.banners.byId).forEach((banner) => {
                    Object.values(INITIAL_STATE.banners.byId).forEach(initialBanner => {
                        if (
                            (banner.template === state.selectedTemplate && banner.image_1 != null) &&
                            (initialBanner.template === state.selectedTemplate && initialBanner.image_1 != null) &&
                            (banner.id === initialBanner.id)
                        ) {
                            yPositionEvery('image_1', banner, initialBanner, action);
                        }
                    });
                });
            });
        case IMAGE_1_RESET_EVERY:
            return produce(state, draft => {
                Object.values(draft.banners.byId).forEach(banner => {
                    Object.values(INITIAL_STATE.banners.byId).forEach(initialBanner => {
                        if (
                            (banner.template === state.selectedTemplate && banner.image_1 != null) &&
                            (initialBanner.template === state.selectedTemplate && initialBanner.image_1 != null) &&
                            (banner.id === initialBanner.id)
                        ) {
                            banner.image_1.x = initialBanner.image_1.x;
                            banner.image_1.y = initialBanner.image_1.y;
                            banner.image_1.scale = initialBanner.image_1.scale;
                            banner.image_1.rotate = initialBanner.image_1.rotate;
                        }
                    });
                });
                draft.templates["byId"][state.selectedTemplate].image1ScaleAll = 1;
                draft.templates["byId"][state.selectedTemplate].image1XPositionAll = 0;
                draft.templates["byId"][state.selectedTemplate].image1YPositionAll = 0;
            });
        case IMAGE_2_SRC_EVERY:
            return produce(state, draft => {
                draft.templates["byId"][state.selectedTemplate].image2SrcAll = action.payload;
                Object.values(draft.banners.byId).forEach((banner) => {
                    if (banner.template === state.selectedTemplate && banner.image_2 != null) {
                        banner.image_2.src = action.payload
                    }
                });
            });
        case IMAGE_2_SIZE_EVERY:
            return produce(state, draft => {
                draft.templates["byId"][state.selectedTemplate].image2ScaleAll = action.payload;
                Object.values(draft.banners.byId).forEach((banner) => {
                    if (banner.template === state.selectedTemplate && banner.image_2 != null) {
                        banner.image_2.scale = action.payload
                    }
                });
            });
        case IMAGE_2_X_POSITION_EVERY:
            return produce(state, draft => {
                draft.templates["byId"][state.selectedTemplate].image2XPositionAll = action.payload;
                Object.values(draft.banners.byId).forEach(banner => {
                    Object.values(INITIAL_STATE.banners.byId).forEach(initialBanner => {
                        if (
                            (banner.template === state.selectedTemplate && banner.image_2 != null) &&
                            (initialBanner.template === state.selectedTemplate && initialBanner.image_2 != null) &&
                            (banner.id === initialBanner.id)
                        ) {
                            xPositionEvery('image_2', banner, initialBanner, action);
                        }
                    });
                });
            });
        case IMAGE_2_Y_POSITION_EVERY:
            return produce(state, draft => {
                draft.templates["byId"][state.selectedTemplate].image2YPositionAll = action.payload;
                Object.values(draft.banners.byId).forEach((banner) => {
                    Object.values(INITIAL_STATE.banners.byId).forEach(initialBanner => {
                        if (
                            (banner.template === state.selectedTemplate && banner.image_2 != null) &&
                            (initialBanner.template === state.selectedTemplate && initialBanner.image_2 != null) &&
                            (banner.id === initialBanner.id)
                        ) {
                            yPositionEvery('image_2', banner, initialBanner, action);
                        }
                    });
                });
            });
        case IMAGE_2_RESET_EVERY:
            return produce(state, draft => {
                Object.values(draft.banners.byId).forEach(banner => {
                    INITIAL_STATE.banners.forEach(initialBanner => {
                        if (
                            (banner.template === state.selectedTemplate && banner.image_2 != null) &&
                            (initialBanner.template === state.selectedTemplate && initialBanner.image_2 != null) &&
                            (banner.id === initialBanner.id)
                        ) {
                            banner.image_2.x = initialBanner.image_2.x;
                            banner.image_2.y = initialBanner.image_2.y;
                            banner.image_2.scale = initialBanner.image_2.scale;
                            banner.image_2.rotate = initialBanner.image_2.rotate;
                        }
                    });
                });
                draft.templates["byId"][state.selectedTemplate].image2ScaleAll = 1;
                draft.templates["byId"][state.selectedTemplate].image2XPositionAll = 0;
                draft.templates["byId"][state.selectedTemplate].image2YPositionAll = 0;
            });
        case HEADLINE_TEXT_EVERY:
            return produce(state, draft => {
                draft.templates["byId"][state.selectedTemplate].headlineTextAll = action.payload;
                Object.values(draft.banners.byId).forEach((banner) => {
                    if (banner.template === state.selectedTemplate && banner.headline != null) {
                        banner.headline.text = action.payload;
                    }
                });
            });
        case HEADLINE_COLOR_EVERY:
            return produce(state, draft => {
                draft.templates["byId"][state.selectedTemplate].headlineTextColorAll = action.payload;
                Object.values(draft.banners.byId).forEach((banner) => {
                    if (banner.template === state.selectedTemplate && banner.headline != null) {
                        banner.headline.fontColor = action.payload;
                    }
                });
            });
        case SUB_HEADLINE_TEXT_EVERY:
            return produce(state, draft => {
                draft.templates["byId"][state.selectedTemplate].subHeadlineTextAll = action.payload;
                Object.values(draft.banners.byId).forEach((banner) => {
                    if (banner.template === state.selectedTemplate && banner.subHeadline != null) {
                        banner.subHeadline.text = action.payload;
                    }
                });
            });
        case SUB_HEADLINE_COLOR_EVERY:
            return produce(state, draft => {
                draft.templates["byId"][state.selectedTemplate].subHeadlineTextColorAll = action.payload;
                Object.values(draft.banners.byId).forEach((banner) => {
                    if (banner.template === state.selectedTemplate && banner.subHeadline != null) {
                        banner.subHeadline.fontColor = action.payload;
                    }
                });
            });
        case CTA_TEXT_EVERY:
            return produce(state, draft => {
                draft.templates["byId"][state.selectedTemplate].ctaSelection = action.payload;
                Object.values(draft.banners.byId).forEach((banner) => {
                    if (banner.template === state.selectedTemplate && banner.cta != null) {
                        banner.cta.text = state.ctaList[action.payload];
                    }
                });
            });
        case CTA_COLOR_EVERY:
            return produce(state, draft => {
                draft.templates["byId"][state.selectedTemplate].ctaTextColorAll = action.payload;
                Object.values(draft.banners.byId).forEach((banner) => {
                    if (banner.template === state.selectedTemplate && banner.cta != null) {
                        banner.cta.fontColor = action.payload;
                    }
                });
            });
        case PROMO_BG_COLOR_EDIT_EVERY:
            return produce(state, draft => {
                draft.templates["byId"][state.selectedTemplate].promoBgColorAll = action.payload;
                Object.values(draft.banners.byId).forEach((banner) => {
                    if (banner.template === state.selectedTemplate && banner.promo?.background != null) {
                        banner.promo.background.css.backgroundColor = action.payload;
                    }
                });
            });
        case PROMO_FONT_COLOR_EVERY:
            return produce(state, draft => {
                draft.templates["byId"][state.selectedTemplate].promoAllTextColor = action.payload;
                Object.values(draft.banners.byId).forEach((banner) => {
                    if (banner.template === state.selectedTemplate && banner.hasOwnProperty('promo')) {
                        banner.promo.textWrap.css.color = action.payload;
                        banner.promo.discount.css.color = action.payload;
                        banner.promo.code.css.color = action.payload;
                    }
                });
            });
        case PROMO_DISCOUNT_TEXT_EVERY:
            return produce(state, draft => {
                draft.templates["byId"][state.selectedTemplate].promoDiscountText = action.payload;
                Object.values(draft.banners.byId).forEach((banner) => {
                    if (banner.template === state.selectedTemplate && banner.promo?.discount != null) {
                        banner.promo.discount.text = action.payload;
                    }
                });
            });
        case PROMO_CODE_TEXT_EVERY:
            return produce(state, draft => {
                draft.templates["byId"][state.selectedTemplate].promoCodeText = action.payload;
                Object.values(draft.banners.byId).forEach((banner) => {
                    if (banner.template === state.selectedTemplate && banner.promo?.code != null) {
                        banner.promo.code.text = action.payload;
                    }
                });
            });
        case BG_COLOR_EDIT_EVERY:
            return produce(state, draft => {
                draft.templates["byId"][state.selectedTemplate].bgColorAll = action.payload;
                Object.values(draft.banners.byId).forEach((banner) => {
                    // ![9,10,19,20].includes(banner.id) <-- this is to ignore those banner.ids that have the black background.
                    if (banner.template === state.selectedTemplate && banner.bgColor != null && ![9, 10, 19, 20, 29, 30, 39, 40, 49, 50].includes(banner.id)) {
                        banner.bgColor = action.payload;
                    }
                });
            });
        case ALL_BANNERS_RESET:
            return produce(state, draft => {

                draft.templates["byId"][state.selectedTemplate] = INITIAL_STATE.templates["byId"][state.selectedTemplate];

                Object.values(state.banners.byId).forEach((banner) => {
                    if (banner.template === state.selectedTemplate) {
                        draft.banners.byId[banner.id] = INITIAL_STATE.banners.byId[banner.id]
                    }
                });

            });
        case ALL_BANNERS_AND_TEMPLATES_RESET:
            return produce(state, draft => {
                const persistedNamingConvention = state.persistentNamingConvention;
                Object.assign(draft, INITIAL_STATE);
                draft.persistentNamingConvention = persistedNamingConvention;
            });
        case ALL_BUT_SELECTED_RESET_TEMPLATES_BANNERS:
            return produce(state, draft => {

                // previouslySelectedTemplate -> we need this in order to avoid the pop-up from showing
                // This sets the selected template that we currently have in design mode
                draft.selectedTemplateInDesignMode = action.payload.selectedTemplate;

                // Obtain previous selected template info
                Object.values(state.templates.byId).forEach(template => {
                    if (template.group === action.payload.selectedTemplate) {
                        draft.templates.byId[action.payload.selectedTemplate] = action.payload.selectedTemplateInfo
                    }
                });

                // Obtain previous selected banner info
                Object.values(draft.banners.byId).forEach(banner => {
                    if (banner.template === action.payload.selectedTemplate) {
                        Object.assign(banner, Object.values(action.payload.bannersByTemplate).find(data => data.id === banner.id));
                    }
                });

                // select template so that it will load into design mode
                draft.selectedTemplate = action.payload.selectedTemplate;
            });
        case INDIVIDUAL_BANNER_RESET:
            return produce(state, draft => {
                if (state.selectedBanner > 0) {
                    draft.banners["byId"][state.selectedBanner] = INITIAL_STATE.banners["byId"][state.selectedBanner];
                }
            });
        case LOAD_BANNERS_FROM_DB:
            return produce(state, draft => {
                Object.values(draft.templates.byId).map(template => Object.assign(template, template.group === action.payload.template_json_data?.group ? action.payload.template_json_data : template));
                Object.values(draft.banners.byId).map(banner => Object.assign(banner, Object.values(action.payload.banner_json_data).find(data => data.id === banner.id) || banner));
            });
        default:
            return state;
    }
}

export default reducer;
