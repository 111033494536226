export const SELECT_BANNER = ' SELECT_BANNER';
export const SELECT_TEMPLATE = 'SELECT_TEMPLATE';
export const TOGGLE_BANNER = 'TOGGLE_BANNER';

export const LINKING_URL = 'LINKING_URL';
export const NAMING_CONVENTION = 'NAMING_CONVENTION';
export const PERSISTENT_NAMING_CONVENTION = 'PERSISTENT_NAMING_CONVENTION';

export const BG_IMAGE_SRC = 'BG_IMAGE_SRC';
export const BG_IMAGE_SIZE = 'BG_IMAGE_SIZE';
export const BG_IMAGE_ROTATE = 'BG_IMAGE_ROTATE';
export const BG_IMAGE_X_POSITION = 'BG_IMAGE_X_POSITION';
export const BG_IMAGE_Y_POSITION = 'BG_IMAGE_Y_POSITION';
export const BG_IMAGE_RESET = 'BG_IMAGE_RESET';

export const LOGO_SRC = 'LOGO_SRC';
export const LOGO_SIZE = 'LOGO_SIZE';
export const LOGO_X_POSITION = 'LOGO_X_POSITION';
export const LOGO_Y_POSITION = 'LOGO_Y_POSITION';
export const LOGO_RESET = 'LOGO_RESET';

export const IMAGE_1_SRC = 'IMAGE_1_SRC';
export const IMAGE_1_SIZE = 'IMAGE_1_SIZE';
export const IMAGE_1_X_POSITION = 'IMAGE_1_X_POSITION';
export const IMAGE_1_Y_POSITION = 'IMAGE_1_Y_POSITION';
export const IMAGE_1_RESET = 'IMAGE_1_RESET';

export const IMAGE_2_SRC = 'IMAGE_2_SRC';
export const IMAGE_2_SIZE = 'IMAGE_2_SIZE';
export const IMAGE_2_X_POSITION = 'IMAGE_2_X_POSITION';
export const IMAGE_2_Y_POSITION = 'IMAGE_2_Y_POSITION';
export const IMAGE_2_RESET = 'IMAGE_2_RESET';

export const HEADLINE_TEXT = 'HEADLINE_TEXT';
export const HEADLINE_FONT_SIZE = 'HEADLINE_FONT_SIZE';
export const HEADLINE_FONT_FAMILY = 'HEADLINE_FONT_FAMILY';
export const HEADLINE_FONT_COLOR = 'HEADLINE_FONT_COLOR';
export const HEADLINE_X_POSITION = 'HEADLINE_X_POSITION';
export const HEADLINE_Y_POSITION = 'HEADLINE_Y_POSITION';

export const SUB_HEADLINE_TEXT = 'SUB_HEADLINE_TEXT';
export const SUB_HEADLINE_FONT_SIZE = 'SUB_HEADLINE_FONT_SIZE';
export const SUB_HEADLINE_FONT_FAMILY = 'SUB_HEADLINE_FONT_FAMILY';
export const SUB_HEADLINE_FONT_COLOR = 'SUB_HEADLINE_FONT_COLOR';
export const SUB_HEADLINE_X_POSITION = 'SUB_HEADLINE_X_POSITION';
export const SUB_HEADLINE_Y_POSITION = 'SUB_HEADLINE_Y_POSITION';

export const CTA_TEXT = 'CTA_TEXT';
export const CTA_FONT_SIZE = 'CTA_FONT_SIZE';
export const CTA_FONT_FAMILY = 'CTA_FONT_FAMILY';
export const CTA_FONT_COLOR = 'CTA_FONT_COLOR';
export const CTA_X_POSITION = 'CTA_X_POSITION';
export const CTA_Y_POSITION = 'CTA_Y_POSITION';

export const PROMO_DISCOUNT_TEXT = 'PROMO_DISCOUNT_TEXT';
export const PROMO_CODE_TEXT = 'PROMO_CODE_TEXT';

export const BG_COLOR_EDIT = 'BG_COLOR_EDIT';

export const ALL_TEXT_COLOR_INDIVIDUAL = 'ALL_TEXT_COLOR_INDIVIDUAL';
export const ALL_TEXT_COLOR_EVERY = 'ALL_TEXT_COLOR_EVERY';

export const BG_IMAGE_SRC_EVERY = 'BG_IMAGE_SRC_EVERY';
export const BG_IMAGE_SIZE_EVERY = 'BG_IMAGE_SIZE_EVERY';
export const BG_IMAGE_ROTATE_EVERY = 'BG_IMAGE_ROTATE_EVERY';
export const BG_IMAGE_X_POSITION_EVERY = 'BG_IMAGE_X_POSITION_EVERY';
export const BG_IMAGE_Y_POSITION_EVERY = 'BG_IMAGE_Y_POSITION_EVERY';
export const BG_IMAGE_RESET_EVERY = 'BG_IMAGE_RESET_EVERY';

export const LOGO_SRC_EVERY = 'LOGO_SRC_EVERY';
export const LOGO_SIZE_EVERY = 'LOGO_SIZE_EVERY';
export const LOGO_X_POSITION_EVERY = 'LOGO_X_POSITION_EVERY';
export const LOGO_Y_POSITION_EVERY = 'LOGO_Y_POSITION_EVERY';
export const LOGO_RESET_EVERY = 'LOGO_RESET_EVERY';

export const IMAGE_1_SRC_EVERY = 'IMAGE_1_SRC_EVERY';
export const IMAGE_1_SIZE_EVERY = 'IMAGE_1_SIZE_EVERY';
export const IMAGE_1_X_POSITION_EVERY = 'IMAGE_1_X_POSITION_EVERY';
export const IMAGE_1_Y_POSITION_EVERY = 'IMAGE_1_Y_POSITION_EVERY';
export const IMAGE_1_RESET_EVERY = 'IMAGE_1_RESET_EVERY';

export const IMAGE_2_SRC_EVERY = 'IMAGE_2_SRC_EVERY';
export const IMAGE_2_SIZE_EVERY = 'IMAGE_2_SIZE_EVERY';
export const IMAGE_2_X_POSITION_EVERY = 'IMAGE_2_X_POSITION_EVERY';
export const IMAGE_2_Y_POSITION_EVERY = 'IMAGE_2_Y_POSITION_EVERY';
export const IMAGE_2_RESET_EVERY = 'IMAGE_2_RESET_EVERY';

export const HEADLINE_TEXT_EVERY = 'HEADLINE_TEXT_EVERY';
export const HEADLINE_COLOR_EVERY = 'HEADLINE_COLOR_EVERY';
export const SUB_HEADLINE_TEXT_EVERY = 'SUB_HEADLINE_TEXT_EVERY';
export const SUB_HEADLINE_COLOR_EVERY = 'SUB_HEADLINE_COLOR_EVERY';

export const CTA_TEXT_EVERY = 'CTA_TEXT_EVERY';
export const CTA_COLOR_EVERY = 'CTA_COLOR_EVERY';

export const PROMO_BG_COLOR_EDIT_EVERY = 'PROMO_BG_COLOR_EDIT_EVERY';
export const PROMO_FONT_COLOR_EVERY = 'PROMO_FONT_COLOR_EVERY';
export const PROMO_DISCOUNT_TEXT_EVERY = 'PROMO_DISCOUNT_TEXT_EVERY';
export const PROMO_CODE_TEXT_EVERY = 'PROMO_CODE_TEXT_EVERY';

export const BG_COLOR_EDIT_EVERY= 'BG_COLOR_EDIT_EVERY';

export const ALL_BANNERS_RESET = 'ALL_BANNERS_RESET';
export const ALL_BANNERS_AND_TEMPLATES_RESET = 'ALL_BANNERS_AND_TEMPLATES_RESET';
export const ALL_BUT_SELECTED_RESET_TEMPLATES_BANNERS = 'ALL_BUT_SELECTED_RESET_TEMPLATES_BANNERS';
export const INDIVIDUAL_BANNER_RESET = 'INDIVIDUAL_BANNER_RESET';

export const LOAD_BANNERS_FROM_DB = 'LOAD_BANNERS_FROM_DB';

