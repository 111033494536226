import React, {useRef} from "react";
import './TopBar.scss';
import vcgtLogo from '../assets/logos/main_logo.svg';
import previewWhiteIcon from '../assets/icons/preview-white.svg';
import downloadIcon from '../assets/icons/download-icon.svg';
import leftCaret from '../assets/icons/left-caret.svg';
import CloudSavedIcon from '../assets/icons/cloud-saved-icon.svg';
import CloudSavingIcon from '../assets/icons/cloud-saving-icon.svg';
import CloudDbErrorIcon from '../assets/icons/cloud-dberror-icon.svg';
import CloudOfflineIcon from '../assets/icons/cloud-offline-icon.svg';
import {ThunkCapture, ThunkPreviewReview, ThunkSaveOrUpdateBanners, thunkSetSavingStatusToSaving} from '../thunks';
import {useDispatch, useSelector} from 'react-redux';
import {getNamingConvention} from '../redux/Banner/banner.selector';
import {allBannersAndTemplatesReset, changeNamingConvention, changePersistentNamingConvention} from '../redux/Banner/banner.actions';
import UserMenu from './UserMenu';
import {useNavigate} from 'react-router-dom';
import {sideMenuSelect} from '../redux/SideMenu/sideMenu.actions';
import {setUsersSaveId} from '../redux/RecentDesigns/RecentDesigns.actions';
import {setNewSlot} from '../redux/Modal/modal.actions';
import {setInCreateDesignMode} from '../redux/TopBar/topBar.actions';


function TopBar({recentDesign}) {

    const ref = useRef();

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const namingConvention = useSelector(getNamingConvention);
    const sideMenuSelection = useSelector(state => state.sideMenu.sideMenuSelection);
    const isOnline = useSelector(state => state.login.isOnline);
    const savingDetermination = useSelector(state => state.login.saving);
    const inCreateDesignMode = useSelector(state => state.topBar.inCreateDesignMode);
    const showStatusIcon = useSelector(state => state.topBar.showStatusIcon);

    const hitEnter = (event) => {
        dispatch(thunkSetSavingStatusToSaving('saving'));
        if (event.key === 'Enter' || event.key === 'Escape') {
            ref.current.blur();
        }
    };

    const createDesignNavigation = () => {
        dispatch(setNewSlot(false));
        dispatch(setInCreateDesignMode(true));
        dispatch(setUsersSaveId(0));
        dispatch(allBannersAndTemplatesReset());
        navigate('/');
        dispatch(sideMenuSelect(0));
    }

    const determineSaveIcon = (isOnline) => {
        if (isOnline) {
            switch (savingDetermination) {
                case 'saving':
                    return CloudSavingIcon;
                case 'saved':
                    return CloudSavedIcon;
                case 'dbError':
                    return CloudDbErrorIcon;
                default:
                    return CloudSavedIcon;
            }
        } else {
            return CloudOfflineIcon
        }
    }

    const namingConventionUpdate = (event) => {
        if (inCreateDesignMode) {
            dispatch(changePersistentNamingConvention(event.target.value));
        }
        dispatch(changeNamingConvention(event.target.value));
    }

    const showOrHideStatusIcon = () => {
        return showStatusIcon ? 'show-status-icon' : 'hide-status-icon';
    }

    return (
        <div id="top-bar">
            <div id="top-bar-left-group">

                <div id="top-bar-logo-container">
                    <img src={vcgtLogo} alt="vcgt-logo"/>
                </div>
                {
                    recentDesign ? null :
                        <>
                            <hr/>
                            <div id="top-bar-project-name-container">
                                <button type="button" id="home-button" onClick={() => navigate('/recent-designs')}>
                                    <img src={leftCaret} alt="<"/>
                                    <span>Home</span>
                                </button>
                                <h3>Project Name:</h3>
                                <input ref={ref}
                                       type="text"
                                       min="0"
                                       max="36"
                                       placeholder="Brand_TemplateType_SpecificDescriptions_Version"
                                       onKeyDown={hitEnter}
                                       value={namingConvention}
                                       onChange={(event) => namingConventionUpdate(event)}
                                       onBlur={() => dispatch(ThunkSaveOrUpdateBanners())}
                                />
                                {
                                    sideMenuSelection || inCreateDesignMode ?
                                        <div id="status-icon" className={showOrHideStatusIcon()} >
                                            <img src={determineSaveIcon(isOnline)} alt="cloud-icon"/>
                                        </div>
                                        : null
                                }
                            </div>
                        </>
                }
            </div>

            <div id="top-bar-right-group">
                <div id="top-bar-buttons-container">
                    {
                        recentDesign ?
                            <button id="create-a-design" type="button" onClick={() => createDesignNavigation()}>
                                <span>Create a Design</span>
                            </button>
                            : <>
                                <button id="download-images-btn" type="button" onClick={() => dispatch(ThunkCapture())}>
                                    <img alt="download" src={downloadIcon}/>
                                    <span>Download</span>
                                </button>
                                <button id="share-review" type="button" onClick={() => dispatch(ThunkPreviewReview())}>
                                    <img alt="preview" src={previewWhiteIcon}/>
                                    <span>Share Link to Preview</span>
                                </button>
                            </>
                    }

                    <UserMenu/>
                </div>
            </div>
        </div>
    );
}


export default TopBar;
