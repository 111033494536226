import produce from 'immer';
import {SET_GRID_ROW_ITEMS, SET_IS_PROJECT_LOADED, SET_PROJECT_NAME_EDITABLE, SET_USERS_SAVE_ID} from './RecentDesigns.types';

const INITIAL_STATE = {
    gridRowItems: [],
    usersSaveId: null,
    isEditable: false,
    isProjectLoaded: false
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_GRID_ROW_ITEMS:
            return produce(state, draft => {
                draft.gridRowItems = action.payload;
            });
        case SET_USERS_SAVE_ID:
            return produce(state, draft => {
                draft.usersSaveId = action.payload
            });
        case SET_PROJECT_NAME_EDITABLE:
            return produce(state, draft => {
                draft.isEditable = action.payload
            });
        case SET_IS_PROJECT_LOADED:
            return produce(state, draft => {
                draft.isProjectLoaded = action.payload
            });
        default:
            return state;
    }
}

export default reducer;
